import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Rating from '../../components/Menu/Rating/Rating';
import backIcon from '../../img/backArrow.svg';
import directionsIcon from '../../img/directions-icon.svg';
import photoPlaceholder from '../../img/no-image-found.png';
import styles from './LocationDetails.module.css';

class PlaceDetails extends Component {
  state = {
    date: null,
    detailsField: {}
  };

  componentDidMount() {
    this.setDate();
    this.selectedPlaceCalls();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.selectedPlaceCalls();
    }
  }

  setDate = () => {
    const datum = {};
    switch (new Date().getDay()) {
      case 0:
        datum.day = 'Sunday';
        datum.number = 0;
        break;
      case 1:
        datum.day = 'Monday';
        datum.number = 1;
        break;
      case 2:
        datum.day = 'Tuesday';
        datum.number = 2;
        break;
      case 3:
        datum.day = 'Wednesday';
        datum.number = 3;
        break;
      case 4:
        datum.day = 'Thursday';
        datum.number = 4;
        break;
      case 5:
        datum.day = 'Friday';
        datum.number = 5;
        break;
      default:
        datum.day = 'Saturday';
        datum.number = 6;
    }
    this.setState({ date: datum });
  }

  selectedPlaceCalls = async () => {
    const { location } = this.props;
    let details = await Promise.all([this.grabDetails(location)]);
    details = this.OpenClose(details[0][0][0]);
    details = this.parseAddress(details);
    details = this.parsePhone(details);
    this.setState({ detailsField: details });
  };

  grabDetails = async (selected) => {
    const detailsFields = await
    Promise.all([this.createGetDetails(selected.placeID)]);
    return detailsFields;
  };

  createGetDetails = async (iD) => {
    const request = {
      placeId: iD,
      fields: ['adr_address', 'opening_hours', 'formatted_phone_number'],
    };

    const resolvedRequest = await Promise.all([this.getPlaceDetails(request)]);
    return resolvedRequest;
  }

  getPlaceDetails = (request) => new Promise((resolve, reject) => {
    const { map, google } = this.props;
    const placesService = new google.maps.places.PlacesService(map);
    this.google = google;

    // eslint-disable-next-line react/prop-types
    placesService.getDetails(request, (results, status) => {
      if (status === this.google.maps.places.PlacesServiceStatus.OK) {
        resolve(results);
      }
      if (status === this.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      }
      reject(new Error(`PlacesService error: ${status}`));
    });
  });

  OpenClose = (details) => {
    const { date } = this.state;
    const detailHours = {};
    const selectedPlaceDetails = details;
    let i = 0;
    let closed = true;
    if (Object.prototype.hasOwnProperty.call(details, 'opening_hours')) {
      for (i = 0; i < details.opening_hours.weekday_text.length; i += 1) {
        const lengthDay = date.day.length;
        const subString = details.opening_hours.weekday_text[i]
          .substring(0, lengthDay);
        if (subString === date.day) {
          detailHours.hours = details.opening_hours.weekday_text[i]
            .substring(lengthDay + 1, details.opening_hours.weekday_text[i]
              .length);
        }
      }
      const currentHour = new Date();
      currentHour.hour = currentHour.getHours();
      currentHour.minute = currentHour.getMinutes();
      if (details.opening_hours.periods[date.number]) {
        const closedHour = details
          .opening_hours.periods[date.number].close.hours;
        const closedMinute = details
          .opening_hours.periods[date.number].close.minutes;
        const openingHour = details
          .opening_hours.periods[date.number].open.hours;
        const openingMinute = details
          .opening_hours.periods[date.number].open.minutes;

        const closingTime = closedHour * 60 + closedMinute;
        const openingTime = openingHour * 60 + openingMinute;
        const currentTime = currentHour.hour * 60 + currentHour.minute;
        if (currentTime <= closingTime && currentTime >= openingTime) {
          closed = false;
        }
        if (closed) {
          detailHours.hours = `Closed Now: ${detailHours.hours}`;
          selectedPlaceDetails.hours = detailHours.hours;
          return selectedPlaceDetails;
        }
        detailHours.hours = `Open Now: ${detailHours.hours}`;
        selectedPlaceDetails.hours = detailHours.hours;
        return selectedPlaceDetails;
      }
      detailHours.hours = 'Closed Today';
      selectedPlaceDetails.hours = detailHours.hours;
      return selectedPlaceDetails;
    }
    detailHours.hours = 'Hours: Unavailable';
    selectedPlaceDetails.hours = detailHours.hours;
    return selectedPlaceDetails;
  }

  parseAddress = (details) => {
    let address = details.adr_address.toString();
    const selectedPlaceDetails = details;
    address = address.replace(/<.*?>/g, '');
    selectedPlaceDetails.address = address;
    return selectedPlaceDetails;
  }

  parsePhone = (details) => {
    const selectedPlaceDetails = details;
    let phone = details.formatted_phone_number;
    if (!phone) {
      phone = 'Phone: Unavailable';
    }
    selectedPlaceDetails.phone = phone;
    return selectedPlaceDetails;
  }

  render() {
    const {
      directionsActive,
      handleDirectionsClick,
      handleDirectionsReset,
      location,
      setSelectedPlace
    } = this.props;
    const { detailsField } = this.state;

    const photoStyle = {
      background: `url(${location.photos ? location.photos[0].getUrl() : photoPlaceholder})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    };

    return (
      <div className={styles.LocationDetails}>
        <header
          className={styles.header}
          // style={{ background: location.color }}
        >
          <h4>{`${location.markerIndex}. ${location.name}`}</h4>
          <Rating rating={location.rating} />
        </header>
        <div className={styles.photo} style={photoStyle} alt="place" />
        {directionsActive ? (
          <>
            <div className={styles.directionsWrapper}>
              <div id="directions-panel" />
            </div>
            <button
              className={styles.backButton}
              onClick={handleDirectionsReset}
              type="button"
            >
              <img src={backIcon} alt="back" />
            </button>
          </>
        ) : (
          <>
            <div className={styles.buttonContainer}>
              <Button
                clickHandler={() => handleDirectionsClick(location.location, 'DRIVING')}
                // color={location.color}
                icon={directionsIcon}
                text="show directions"
              />
            </div>
            <div className={styles.content}>
              <ul className={styles.detailsList}>
                <li>{detailsField.address}</li>
                <li>{detailsField.phone}</li>
                <li>{detailsField.hours}</li>
              </ul>
            </div>
            <button
              className={styles.backButton}
              onClick={() => setSelectedPlace(null)}
              type="button"
            >
              <img src={backIcon} alt="back" />
            </button>
          </>
        )}
      </div>
    );
  }
}

PlaceDetails.propTypes = {
  directionsActive: PropTypes.bool.isRequired,
  handleDirectionsClick: PropTypes.func.isRequired,
  handleDirectionsReset: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setSelectedPlace: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  photoGalleryActive: PropTypes.bool.isRequired,
  togglePhotoGallery: PropTypes.func.isRequired,
};

export default PlaceDetails;
