import React from 'react';
import styles from './ProductLineItem.module.css';

export type Option = {
  id: string;
  commRID: string;
  text: string;
}
interface IProductLineItemProps {
  clickHandler: (option:Option) => void,
  option:Option,
  selectedCommunityRID: string
}
const ProductLineItem = ({
  clickHandler,
  option,
  selectedCommunityRID,
}:IProductLineItemProps) => (
  <div
    onClick={() => clickHandler(option)}
    className={selectedCommunityRID === option.commRID ? styles.SelectedProductLineItem : styles.ProductLineItem}
    role="button"
    tabIndex={0}
  >
    <span>{option.text}</span>
  </div>
);

export default ProductLineItem;
