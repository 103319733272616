import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingPage.module.css';

interface ILoadingPageProps {
  message:string
}
const LoadingPage = ({
  message = ''
}:ILoadingPageProps) => (
  <div className={styles.loadingPage}>
      <div className={styles.circles}>
          <div />
          <div />
          <div />
          <div />
      </div>
      <div className={styles.messagewrapper}>
        <span className={styles.message}>{message}</span>
      </div>
  </div>
);

export default LoadingPage;
