import React from 'react';
import styles from './MapModeNav.module.css';
import markerIcon from '../../img/marker-icon.svg';
import surroundingAreaIcon from '../../img/surrounding_area_icon.svg';
import {ILegendItemConfig, ILegendItemConfigItem} from "./types";

function formatLotStatusKey(key: string) {
  let formattedKey = key;
  if (key === 'QUICKMOVEIN') {
    formattedKey = formattedKey.replace('MOVEIN', ' Move-ins');
  }
  return formattedKey.toLowerCase();
}

export function listLegendItems(configs: ILegendItemConfig, mapMode: string) {
  switch (mapMode) {
    case 'surrounding area':
      return configs.placesConfig.map((config) => (
        <div key={"surrounding_" + config.name}  className={styles.legendItem}>
          <div style={{ backgroundColor: config.color }} />
          <span>{config.name}</span>
        </div>
      ));
    case 'points of interest':
      return configs.poiConfig.map((config) => (
        <div key={"poi" + config.name} className={styles.legendItem}>
          <div style={{ backgroundColor: config.color }} />
          <span>{config.name}</span>
        </div>
      ));
    case 'home sites':
      return Object.entries(configs.lotStatusConfig)
        .filter(([key]) => !['EMPTY', 'UNKNOWN'].includes(key))
        .map(([key, value]) => (
          <div key={"homes_" + key} className={styles.legendItem}>
            <div style={{ backgroundColor: value.color }} />
            <span>{formatLotStatusKey(key)}</span>
          </div>
        ));
    default:
      console.error(`mapMode: ${mapMode} not recognized`);
      return null;
  }
}

export function listMapModeButtons(enabledMapModes: string[], mapMode: string, setMapMode: (mapMode: string) => void) {
  let mapModeConfig = [
    {
      icon: surroundingAreaIcon,
      mapMode: 'surrounding area'
    },
    {
      icon: markerIcon,
      mapMode: 'points of interest'
    },
    {
      icon: surroundingAreaIcon,
      mapMode: 'home sites',
      text: 'homesites'
    }
  ];
  mapModeConfig = mapModeConfig.filter((config) => config.mapMode !== mapMode);

  if (enabledMapModes) {
    mapModeConfig = mapModeConfig.filter(
      (config) => enabledMapModes.includes(config.mapMode)
    );
  }

  return mapModeConfig.map((config, index) => (
    <>
      <button type="button" onClick={() => setMapMode(config.mapMode)}>
        <img src={config.icon} alt="marker" />
        <div>Switch view to</div>
        <div>{config.text || config.mapMode}</div>
      </button>
      {index < mapModeConfig.length - 1 && (
        <div className={styles.divider} />
      )}
    </>
  ));
}
