export enum MarkerIdType {
    BottomLeft='BottomLeft',
    BottomRight='BottomRight',
    TopRight='TopRight',
    TopLeft='TopLeft'

}
function getEditAreaBoundsFromCornerMove(event: any, markerId: MarkerIdType, getOverrideLotMapBounds: () => google.maps.LatLngBounds, updateOverrideLotMapBounds: (overrideLotMapBounds: google.maps.LatLngBounds) => void) {
    const overrideLotMapBounds = getOverrideLotMapBounds();
    if (!overrideLotMapBounds) {
        return;
    }

    const southWest = overrideLotMapBounds.getSouthWest();
    const northEast = overrideLotMapBounds.getNorthEast();
    let south = southWest.lat();
    let west = southWest.lng();
    let north = northEast.lat();
    let east = northEast.lng();

    const newLat = parseFloat(event.latLng.lat());
    const newLng = parseFloat(event.latLng.lng());

    if (markerId === MarkerIdType.BottomLeft) {
        south = newLat;
        west = newLng;
    }
    else if (markerId === MarkerIdType.BottomRight) {
        south = newLat;
        east = newLng;
    }
    else if (markerId === MarkerIdType.TopRight) {
        north = newLat;
        east = newLng;
    }
    else if (markerId === MarkerIdType.TopLeft) {
        north = newLat;
        west = newLng;
    }

    updateOverrideLotMapBounds(new google.maps.LatLngBounds(
        new google.maps.LatLng(south, west),
        new google.maps.LatLng(north, east)
    ));
}

export function addEditMarker(map: google.maps.Map, position: google.maps.LatLng, markerId: MarkerIdType, getOverrideLotMapBounds: () => google.maps.LatLngBounds, updateOverrideLotMapBounds: (overrideLotMapBounds: google.maps.LatLngBounds) => void) : google.maps.Marker {
    const marker = new google.maps.Marker({
        position: position,
        map: map,
        title: "Move me! " + markerId,
        draggable: true,
    });
    marker.addListener('drag', (event:any) => getEditAreaBoundsFromCornerMove(event, markerId, getOverrideLotMapBounds, updateOverrideLotMapBounds));

    return marker;
}

export function updateEditModeMarkers(map: google.maps.Map, editModeMarkers: any, svgBounds: google.maps.LatLngBounds, getOverrideLotMapBounds: () => google.maps.LatLngBounds, updateOverrideLotMapBounds: (overrideLotMapBounds: google.maps.LatLngBounds) => void) {

    const southWest = svgBounds.getSouthWest();
    const northEast = svgBounds.getNorthEast();
    var bottomLeft = southWest;
    var topLeft = new google.maps.LatLng(
        northEast.lat(),
        southWest.lng()
    );
    var topRight = northEast;
    var bottomRight = new google.maps.LatLng(
        southWest.lat(),
        northEast.lng()
    )

    if (!editModeMarkers)
    {
        const marker1 = addEditMarker(map, bottomLeft, MarkerIdType.BottomLeft, getOverrideLotMapBounds, updateOverrideLotMapBounds);
        const marker2 = addEditMarker(map, bottomLeft, MarkerIdType.BottomRight, getOverrideLotMapBounds, updateOverrideLotMapBounds);
        const marker3 = addEditMarker(map, bottomLeft, MarkerIdType.TopRight, getOverrideLotMapBounds, updateOverrideLotMapBounds);
        const marker4 = addEditMarker(map, bottomLeft, MarkerIdType.TopLeft, getOverrideLotMapBounds, updateOverrideLotMapBounds);

        editModeMarkers = {
            [MarkerIdType.BottomLeft]: marker1,
            [MarkerIdType.BottomRight]: marker2,
            [MarkerIdType.TopRight]: marker3,
            [MarkerIdType.TopLeft]: marker4
        }
    }
    else
    {
        editModeMarkers = {...editModeMarkers};
        editModeMarkers.BottomLeft.setPosition(bottomLeft);
        editModeMarkers.BottomRight.setPosition(bottomRight);
        editModeMarkers.TopRight.setPosition(topRight);
        editModeMarkers.TopLeft.setPosition(topLeft);
    }

    return editModeMarkers;
}