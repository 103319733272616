import React from 'react';
import { listLegendItems, listMapModeButtons } from './utils';
import GlobalConfig from '../../utils/GlobalConfig';
import styles from './MapModeNav.module.css';
import {ILegendItemConfig, ILegendItemConfigItem} from "./types";

interface IMapModeNavProps {
  collapseMapMode: boolean,
  lotStatusConfig: ILegendItemConfigItem[],
  mapMode: string,
  placesConfig: ILegendItemConfigItem[],
  poiConfig: ILegendItemConfigItem[],
  setMapMode: (mapMode: string) => void
}

export default function MapModeNav({
    collapseMapMode,
    lotStatusConfig,
    mapMode,
    placesConfig,
    poiConfig,
    setMapMode
  }: IMapModeNavProps) {
  const configs : ILegendItemConfig = {
    lotStatusConfig,
    placesConfig,
    poiConfig
  };
  return (
    <nav className={`${styles.MapModeNav} ${collapseMapMode ? styles.collapse : ''}`}>
      <div className={styles.legend}>
        {listLegendItems(configs, mapMode)}
      </div>
      {listMapModeButtons(GlobalConfig.get(GlobalConfig.Key.ENABLED_MAP_MODES), mapMode, setMapMode)}
    </nav>
  );
}
