import {useMediaQuery} from "usehooks-ts";
import App from "./App/App";
import {useLocationState, useQueryState} from "use-location-state";
import queryString from "query-string";
import GlobalConfig from "../utils/GlobalConfig";



function initializeMapMode() {
    const mapModeQuery = queryString.parse(window.location.search).mapMode;
    const enabledMapModes = GlobalConfig.get(GlobalConfig.Key.ENABLED_MAP_MODES);

    if (mapModeQuery && enabledMapModes.includes(mapModeQuery)) {
        return mapModeQuery;
    } else {
        return enabledMapModes[0];
    }
}


const AppContainer: React.FC<{editMode:boolean}> = (props) => {
    const { editMode } = props;
    const mobileMode = useMediaQuery('(max-width: 800px)');

    const [placeId, setPlaceId] = useQueryState("placeId", "");
    const [mapLocation, setMapLocation] = useQueryState("map", "");
    const [mapMode, setMapMode] = useQueryState("mapmode", "");

    const [unrealUrl, ] = useQueryState("unrealUrl", "");
    const unrealUrlToUse = GlobalConfig.get(GlobalConfig.Key.ENABLE_UNREAL) && mapLocation === "" ? unrealUrl : undefined;

    const mapModeToUse = mapMode === "" ? initializeMapMode() : mapMode;
    const placeIdToUse = placeId === "" ? null : placeId;
    const mapTypeId = queryString.parse(window.location.search).mapTypeId;
    const mapsAuth = queryString.parse(window.location.search).mapsAuth || GlobalConfig.get(GlobalConfig.Key.MAPS_AUTH);

    return <App mobileMode={mobileMode}
                editMode={editMode}
                unrealUrl={unrealUrlToUse}
                mapsAuth={mapsAuth}
                mapTypeId={mapTypeId}
                placeId={placeIdToUse}
                mapMode={mapModeToUse}
                setMapMode={setMapMode}
                setPlaceId={(place: string) => { setPlaceId(place); } }
                mapLocation={mapLocation !== "" ? mapLocation : null}
    />
}

export default AppContainer;
