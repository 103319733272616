const removeText = (description) => {
  const oldDesc = description.split(' ');

  let newDesc = '';
  oldDesc.forEach((descriptor, index) => {
    if (index === 0) {
      newDesc = descriptor;
    }
    if (index !== (oldDesc.length - 1) && index > 0) {
      newDesc = `${newDesc} ${descriptor}`;
    }
  });
  return newDesc;
};

// eslint-disable-next-line import/prefer-default-export
export function formatModelData(models) {
  const newKeys = Object.keys(models);
  const modelsObj = {};

  newKeys.forEach((key) => {
    modelsObj[key] = [];
    models[key].forEach((model) => {
      //check for marketingname
      if (!model.MarketingName) {
        model.MarketingName = model.Name;
      }

      //check for text in desc field
      if (model.DescrBaths.includes('Baths')) {
        model.DescrBaths = removeText(model.DescrBaths);
      }
      if (model.DescrBedrooms.includes('Bed')) {
        model.DescrBedrooms = removeText(model.DescrBedrooms);
      }
      if (model.DescrSqf.includes('Sq')) {
        model.DescrSqf = removeText(model.DescrSqf);
      }
      //check for format other than number
      if (model.DescrBaths.includes('/')) {
        model.DescrBaths = model.DescrBaths.replace(/ 1\/2/g, '.5');
      }
      if (model.DescrBedrooms.includes('/')) {
        model.DescrBedrooms = model.DescrBedrooms.replace(/ 1\/2/g, '.5');
      }

      model.newField = 'new field';


      model.id = model.ModelRID;
      // model.id needs to be used. something duplicate field.
      model.name = model.Name.split('(')[0];
      model.nhPhoto = model.PresentationImageUrl;

      model.sqf = model.Sqf;
      model.bath = model.NumBedrooms;
      model.bed = model.NumBaths;
      model.price = model.BasePrice;
      if (model.MarketingName) {
        model.marketingName = model.MarketingName;
      } else {
        model.marketingName = model.Name;
      }
      model.status = 'AFP';

      modelsObj[key].push(model);
    });
  });

  return modelsObj;
}
