

const GlobalConfigKeys = {
  KOVA_URL:'KOVA_URL',
  KOVA_AUTH:'KOVA_AUTH',
  SOCKET_URL:'SOCKET_URL',
  S3_URL:'S3_URL',
  S3_ID:'S3_ID',
  S3_KEY:'S3_KEY',
  S3_REGION:'S3_REGION',
  S3_BUCKET:'S3_BUCKET',
  VSC_API_URL:'VSC_API_URL',
  BUILDER:'BUILDER',
  ENABLED_MAP_MODES: 'ENABLED_MAP_MODES',
  MARKETHOME_INFO_OPTSELRID: 'MARKETHOME_INFO_OPTSELRID',
  OVERRIDES: 'OVERRIDES',
  ENABLE_UNREAL: 'ENABLE_UNREAL', // When set to true, enable controlling Unreal WebsiteController by specifying the URL in fragment.
  MAP_GESTURE_HANDLING: 'MAP_GESTURE_HANDLING',
  SHOW_LOT_PREMIUMS: 'SHOW_LOT_PREMIUMS',
  MAPS_AUTH: 'MAPS_AUTH',
  HUBSPOT_ANALYTICS_ID: 'HUBSPOT_ANALYTICS_ID',
  GOOGLE_ANALYTICS_ID: 'GOOGLE_ANALYTICS_ID'
};

export type GlobalConfigKey = keyof typeof GlobalConfigKeys;

class GlobalConfig {
  private config: Partial<Record<GlobalConfigKey, string>>;
  public readonly Key: Record<GlobalConfigKey, GlobalConfigKey>;

  constructor() {
    this.config = {};
    this.Key = GlobalConfigKeys as Record<GlobalConfigKey, GlobalConfigKey>;
  }

  setConfig(config: Partial<Record<GlobalConfigKey, string>>) {
    this.config = config;
  }

  get(key:GlobalConfigKey): any {
    return this.config[key]!;
  }
}

const configInstance = new GlobalConfig();

export default configInstance;

export function getEnabledMapModes(config: GlobalConfig) {
  return config.get("ENABLED_MAP_MODES") as string[];
}
