import React from 'react';
import PropTypes from 'prop-types';
import ProductLineItem, {Option} from './ProductLineItem/ProductLineItem';
import styles from './ProductLine.module.css';

export type Community = {
  commRID: string;
  key: number;
  id: string;
  text: string;
}
export type Collection = {
  communityRID: string;
  name: string;
}
export type SalesCenterData = {
  locationName: string;
  collections: Collection[];
}

interface IProductLineProps {
  handleProductLine: (id: string, text: string, commRID: string) => void,
  toggleProductLineMenu: () => void,
  activeCollections: string[],
  salesCenterData:SalesCenterData,
  selectedCommunityRID:string,
}
const ProductLine = ({
  handleProductLine,
  toggleProductLineMenu,
  activeCollections,
  salesCenterData,
  selectedCommunityRID,
}:IProductLineProps) => {
  const mocklist:Community[] = [
    {
      text: 'All Communities - ALL',
      id: 'all',
      key: 0,
      commRID: 'all' // <-- used to default this option on
    },
  ];

  salesCenterData.collections.forEach((c, i) => {
    const newObj:Community = {
      text: `${salesCenterData.locationName} - ${c.name}`,
      id: c.name.toLowerCase(),
      key: i + 1,
      commRID: c.communityRID,
    }
    mocklist.push(newObj);
  });

  // // initialMock = newArray;

  // // initialMock = initialMock.map((lotObj) => {
  // //   const newLotObj = lotObj;

  // //   if (!activeCollections.includes(lotObj.commRID)) {
  // //     newLotObj.enabled = false;
  // //   } else {
  // //     newLotObj.enabled = true;
  // //   }
  // //   return newLotObj;
  // // });

  // const list = [
  //   {
  //     text: 'All Communities - ALL',
  //     id: 'all',
  //     key: 0,
  //     commRID: 'all' // <-- used to default this option on
  //   },
  //   // {
  //   //   text: 'Green Valley Ranch - ALL',
  //   //   id: 'GVR',
  //   //   key: 10,
  //   //   commRID: 2
  //   // },
  //   {
  //     text: 'Green Valley Ranch - Carriage House',
  //     id: 'carriage',
  //     key: 1,
  //     commRID: 219
  //   },
  //   {
  //     text: 'Green Valley Ranch - Horizon',
  //     id: 'horizon',
  //     key: 2,
  //     commRID: 224
  //   },
  //   {
  //     text: 'Green Valley Ranch - Meridian',
  //     id: 'meridian',
  //     key: 3,
  //     commRID: 229
  //   },
  //   {
  //     text: 'Green Valley Ranch - Bungalow',
  //     id: 'bungalows',
  //     key: 4,
  //     commRID: 230
  //   },
  //   {
  //     text: 'Green Valley Ranch - Retreat',
  //     id: 'retreat',
  //     key: 5,
  //     commRID: 233
  //   },
  //   // {
  //   //   text: 'Banning Lewis Ranch - ALL',
  //   //   id: 'BLR',
  //   //   key: 9,
  //   //   commRID: 1
  //   // },
  //   {
  //     text: 'Banning Lewis Ranch - Fairmont',
  //     id: 'fairmont BLR',
  //     key: 6,
  //     commRID: 225
  //   },
  //   {
  //     text: 'Banning Lewis Ranch - Bungalows',
  //     id: 'bungalows BLR',
  //     key: 7,
  //     commRID: 232
  //   },
  //   {
  //     text: 'Banning Lewis Ranch - Retreat',
  //     id: 'retreat BLR',
  //     key: 8,
  //     commRID: 227
  //   },
  // ];

  const selectCollection = (option:Option) => {
    toggleProductLineMenu();
    handleProductLine(option.id, option.text, option.commRID);
  };

  const selection = () => (
    mocklist.map((option) => {
      if ((option.commRID === 'all')
      || activeCollections.includes(option.commRID)) {
        return (
          <ProductLineItem
            key={option.key}
            clickHandler={selectCollection}
            option={option}
            selectedCommunityRID={selectedCommunityRID}
          />
        );
      }
    })
  );

  return (
    <div className={styles.ProductLine}>
      <div className={styles.Header}> Select a Community / Collection below...</div>
      {selection()}
    </div>
  );
};

export default ProductLine;
