import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styles from './Map.module.css';
import GlobalConfig from "../../utils/GlobalConfig";

const Map = ({
  applyLotmapOverlay,
  auxLotData,
  mapClicked,
  streetVIconClicked,
  applyCommunityOverlays,
  mapMode,
  pinsActive,
  handleSelectedLotRID,
  selectedLotRID,
  lotInfo,
  lotType,
  activeProductLine,
  newHomeSelectedLotRID,
  salesCenterData,
  toggleVisibleSplash,
  splash,
  socket,
  roomId,
  builderSelected,
  changeSelectedMap,
  selectedMap,
  changeLoadingStatus,
  lotCollections
}) => {
  useEffect(() => {
    applyCommunityOverlays();
    applyLotmapOverlay();
    checkIfLoaded();
  }, []);

  const [mapNames, setMapNames] = useState([]);

  const [LoadingCheck, setLoadingCheck] = useState(false);

  const checkIfLoaded = () => {
    let svg = document.getElementById(mapNames[0]);
    if (splash) {
      svg = document.getElementById(mapNames[1]);
    }
    svg.onload = () => {setLoadingCheck(true) };

    if (svg) {
      changeLoadingStatus('Map');
    } else {
      setTimeout(() => {
        checkIfLoaded();
      }, 1000);
    }
  };

  const oakwoodHomesSelect = () => {
    socket.emit('select builder', {
      name: 'select builder',
      roomId,
      data: {
        isMainBuilder: true
      }
    });
    toggleVisibleSplash();
  };

  const classicHomesSelect = () => {
    socket.emit('alt builder map', {
      name: 'alt builder map',
      roomId
    });
    socket.emit('select builder', {
      name: 'select builder',
      roomId,
      data: {
        isMainBuilder: false
      }
    });
  };

  const allLotProxy = lotCollections;

  const handleLotClick = (lot) => {
    if (!['UNKNOWN', 'EMPTY'].includes(lot.status)) {
      handleSelectedLotRID(lot.selector, lot);
      return lot;
    }
    return null;
  };

  const handleFill = (proxy) => {
    if (mapMode !== 'home sites') {
      return '#fff';
    }
    if (activeProductLine === proxy.productLine || activeProductLine === 'all') {
      if (newHomeSelectedLotRID) {
        if (newHomeSelectedLotRID === `#_${proxy.lotRID}`) {
          return proxy.Color;
        }
        return '#fff';
      }
      if (proxy.AvailableModels && lotType === 'AFP') {
        const modelNames = [];
        proxy.AvailableModels.forEach((model) => {
          if (model.Key.includes('(')) {
            modelNames.push(model.Key.split('(')[0]);
          } else if (model.Key.includes('/')) {
            modelNames.push(model.Key.split('/')[0]);
          } else {
            modelNames.push(model.Key);
          }
        });
        const checkLotHasModel = modelNames.includes(lotInfo.name)
          && proxy.selector;
        if (proxy.status === 'AVAILABLE' && checkLotHasModel) {
          return proxy.Color;
        }
      }
      if (selectedLotRID && selectedLotRID === `#_${proxy.lotRID}`) {
        return proxy.Color;
      }
      if (!selectedLotRID) {
        return proxy.Color;
      }
    }

    return '#cecece';
  };

  const listLots = () => allLotProxy.map((proxy) => (
    <SvgProxy
      key={proxy.selector}
      selector={proxy.selector}
      fill={proxy.noSlsOrd ? '#cecece' : handleFill(proxy)}
      stroke="black"
      onClick={proxy.noSlsOrd ? null : () => handleLotClick(proxy)}
      class={styles.lot}
      id={proxy.selector}
    />
  ));

  const hideLots = () => allLotProxy.map((proxy) => {
    return (
      <SvgProxy
        key={proxy.selector}
        selector={proxy.selector}
        fill="#cecece"
        stroke="black"
          // for some reason a method here is required for the text changes to apply
        onClick={() => console.log('')}
        class={styles.lot}
        id={proxy.selector}
      />
    );
  });

  const findClassicLots = () => (
    <SvgProxy
        // key={proxy.selector}
      selector="#ClassicLots"
        // fill={proxy.noSlsOrd ? '#cecece' : handleFill(proxy)}
      stroke="green"
      onClick={classicHomesSelect}
        // class={styles.lot}
      id="ClassicLots"
    />
  );
  const findOakwoodLots = () => (
    <SvgProxy
        // key={proxy.selector}
      selector="#OakwoodLots"
        // fill={proxy.noSlsOrd ? '#cecece' : handleFill(proxy)}
      stroke="black"
      onClick={oakwoodHomesSelect}
        // class={styles.lot}
      id="ClassicLots"
    />
  );

  const getActiveLine = allLotProxy.filter((active) => {
    if (activeProductLine === 'all') {
      return active;
    }
    return active.productLine === activeProductLine;
  });

  const setTextVisibility = () => {
    let visibility = 'visible';
    if (mapMode !== 'home sites' || selectedLotRID || (mapMode === 'home sites' && !pinsActive)) {
      visibility = 'hidden';
    }

    return visibility;
  };

  const listText = () => getActiveLine.map((proxy) => {
    if (!splash || builderSelected) {
      return (
        <SvgProxy
          key={proxy.textSelector}
          selector={`${proxy.textSelector} text`}
          stroke={(proxy.enabled && !proxy.noSlsOrd) ? 'black' : '#cecece'}
          onClick={proxy.noSlsOrd ? null : () => () => handleLotClick(proxy)}
          onElementSelected={(node) => {
            let element;
            if (Array.isArray(node)) {
              [element] = node;
            } else {
              element = node;
            }
            if (proxy.status === 'MODEL') {
              element.setAttribute('x', -1.25);
            } else {
              element.setAttribute('x', 0);
            }
            element.setAttribute('y', 2);
          }}
          visibility={setTextVisibility()}
        >
          {`${proxy.lotNum}`}
        </SvgProxy>
      );
    }
  });

  const hideText = () => getActiveLine.map((proxy) => (
    <SvgProxy
      key={proxy.textSelector}
      id={proxy.textSelector}
      selector={`${proxy.textSelector} text`}
      onClick={() => console.log('')}
      stroke="black"
      fill="none"
      visibility="hidden"
    />
  ));

  const fillAuxLots = () => Object.values(auxLotData).flat().map((lot) => (
    <SvgProxy
      key={lot.LotRID}
      selector={`#_${lot.LotRID}`}
      fill="#cecece"
    />
  ));

  const disableLotCircles = () => allLotProxy.map((proxy) => (
    <SvgProxy
      key={`${proxy.textSelector}C`}
      selector={`${proxy.textSelector} circle`}
      visibility="hidden"
    />
  ));

  const disableAuxLotCircles = () => Object.values(auxLotData)
    .flat()
    .map((lot) => (
      <SvgProxy
        key={`${lot.LotRID}C`}
        selector={`#L_${lot.LotRID} circle`}
        visibility="hidden"
      />
    ));

  const disableAuxLotText = () => Object.values(auxLotData)
    .flat()
    .map((lot) => (
      <SvgProxy
        key={`${lot.LotRID}T`}
        selector={`#L_${lot.LotRID} text`}
        visibility="hidden"
      />
    ));

  const changeMap = (SalesCenter) => {
    changeSelectedMap(SalesCenter);
  };

  // Would like to change the renderlotmap() function to use this dynamically
  // and not rely on seperate clauses for each case, but I ran into several
  // issues so it has been commented out.

  // if splash then if the map is the lotmap add a click handler AND a background
  // else do not.
  // const lotmapFunction = (SVGid, splash, imageHref, SVGloader, SVGloaderclick) => (
  //   <svg id={`${SVGid}`}>
  //     {splash ? null : <image href={`${imageHref}`} height="100%" width="100%" />}
  //     <image href={`${imageHref}`} height="100%" width="100%" />
  //     <SvgLoader
  //       path={`${SVGloader}`}
  //       onClick={() => {
  //         if (splash) {
  //           changeMap(SVGloaderclick);
  //         }
  //       }}
  //     >
  //       {auxLotData && fillAuxLots()}
  //       {auxLotData && disableAuxLotCircles()}
  //       {auxLotData && disableAuxLotText()}
  //       {disableLotCircles()}
  //       {listText()}
  //       {listLots()}
  //     </SvgLoader>
  //   </svg>
  // );

  const renderLotmap = () => {
    const newSalesCenters = [];
    const arrayOfLotMapIds = [];

    let splash = false;
    if (salesCenterData.altBuilders.length) {
      splash = true;
      newSalesCenters.push(salesCenterData);
    }

    newSalesCenters.push(salesCenterData);

    if (splash) {
      return newSalesCenters.map((SalesCenter, index) => {
        let id = `${SalesCenter.lotMaps[0].lotMapKey}-lotmap`;
        // const imageHref = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap_Background.png`;
        // let SVGloader = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap.svg`;
        // const SVGloaderclick = SalesCenter;
        if (index === 0) {
          if (!mapNames.includes(id)) {
            arrayOfLotMapIds.push(id);
            setMapNames([...arrayOfLotMapIds]);
          }
          // lotmapFunction = (SVGid, splash, imageHref, SVGloader, SVGloaderclick)
          // return lotmapFunction(id, splash, imageHref, SVGloader, false);
          return (
            <svg id={`${SalesCenter.lotMaps[0].lotMapKey}-lotmap`}>
              <image href={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap_Background.png`} height="100%" width="100%" />
              <SvgLoader
                path={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap.svg`}
                onClick={() => {}}
              >
                {auxLotData && fillAuxLots()}
                {auxLotData && disableAuxLotCircles()}
                {auxLotData && disableAuxLotText()}
                {disableLotCircles()}
                {listText()}
                {listLots()}
              </SvgLoader>
            </svg>
          );
        }
        id = `${SalesCenter.lotMaps[0].lotMapKey}-lotmap-splash`;
        if (!mapNames.includes(id)) {
          arrayOfLotMapIds.push(id);
          setMapNames([...arrayOfLotMapIds]);
        }
        return (
          <svg id={`${SalesCenter.lotMaps[0].lotMapKey}-lotmap-splash`}>
            {/* <image href={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap_Background.png`} height="100%" width="100%" /> */}
            <SvgLoader
              path={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${SalesCenter.lotMaps[0].lotMapKey}_LotMap_Splash.svg`}
              // onClick={() => {
              //   changeMap(SalesCenter);
              // }}
            >
              {auxLotData && fillAuxLots()}
              {auxLotData && disableAuxLotCircles()}
              {auxLotData && disableAuxLotText()}
              {disableLotCircles()}
              {findClassicLots()}
              {findOakwoodLots()}
            </SvgLoader>
          </svg>
        );
      });
    }
    if (salesCenterData.lotMaps.length > 1) {
      return newSalesCenters[0].lotMaps.map((lotMap) => {
        let isSelected = false;
        if (selectedMap) {
          isSelected = selectedMap.mapNumber === lotMap.mapNumber;
        }
        const id = `${newSalesCenters[0].lotMaps[0].lotMapKey}-lotmap-${lotMap.mapNumber}`;
        if (!mapNames.includes(id)) {
          arrayOfLotMapIds.push(id);
          setMapNames([...arrayOfLotMapIds]);
        }
        return (
          <svg id={`${newSalesCenters[0].lotMaps[0].lotMapKey}-lotmap-${lotMap.mapNumber}`}>
            <image href={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${newSalesCenters[0].lotMaps[0].lotMapKey}_LotMap_Background_${lotMap.mapNumber}.png`} height="100%" width="100%" />
            <SvgLoader
              path={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${newSalesCenters[0].lotMaps[0].lotMapKey}_LotMap_${lotMap.mapNumber}.svg`}
              onClick={() => {
                changeMap(lotMap);
              }}
            >
              {auxLotData && fillAuxLots()}
              {auxLotData && disableAuxLotCircles()}
              {auxLotData && disableAuxLotText()}
              {disableLotCircles()}
              {isSelected ? listText() : hideText()}
              {isSelected ? listLots() : hideLots()}
            </SvgLoader>
          </svg>
        );
      });
    }
    const id = `${newSalesCenters[0].lotMaps[0].lotMapKey}-lotmap`;
    if (!mapNames.includes(id)) {
      arrayOfLotMapIds.push(id);
      setMapNames([...arrayOfLotMapIds]);
    }
    return (
      <svg id={`${newSalesCenters[0].lotMaps[0].lotMapKey}-lotmap`}>
        <image href={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${newSalesCenters[0].lotMaps[0].lotMapKey}_LotMap_Background.png`} height="100%" width="100%" />
        <SvgLoader path={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/${newSalesCenters[0].lotMaps[0].lotMapKey}_LotMap.svg`}>
          {auxLotData && fillAuxLots()}
          {auxLotData && disableAuxLotCircles()}
          {auxLotData && disableAuxLotText()}
          {disableLotCircles()}
          {listText()}
          {listLots()}
        </SvgLoader>
      </svg>
    );
  };

  const listAuxCommunityOverlays = () => {
    const overlayIds = [];
    if (salesCenterData.allAuxCollections) {
      salesCenterData.allAuxCollections.forEach((auxCollection) => {
        overlayIds.push(auxCollection.lotMapKey);
      });
    }

    return overlayIds.map((overlayId) => (
      <svg key={overlayId} id={overlayId}>
        <SvgLoader path={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}amenity-map/overlay/${overlayId}_Topo.svg`} />
      </svg>
    ));
  };

  const stateOfPano = () => {
    if (streetVIconClicked && mapClicked) {
      return 'panoActive';
    }
    return 'panoInactive';
  };

  return (
    <>
      <div className={styles.Map} id="map" />
      <div className={styles[stateOfPano()]} id="pano" />
      {renderLotmap()}
      {listAuxCommunityOverlays()}
    </>
  );
};

Map.defaultProps = {
  lotInfo: null,
  lotType: null,
  selectedLotRID: null,
  newHomeSelectedLotRID: null,
};

Map.propTypes = {
  auxLotData: PropTypes.object,
  applyCommunityOverlays: PropTypes.func.isRequired,
  applyLotmapOverlay: PropTypes.func.isRequired,
  streetVIconClicked: PropTypes.bool.isRequired,
  mapClicked: PropTypes.bool.isRequired,
  mapMode: PropTypes.string.isRequired,
  pinsActive: PropTypes.bool.isRequired,
  handleSelectedLotRID: PropTypes.func.isRequired,
  selectedLotRID: PropTypes.string,
  lotInfo: PropTypes.object,
  lotType: PropTypes.string,
  newHomeSelectedLotRID: PropTypes.string,
  activeProductLine: PropTypes.string.isRequired,
  activeCollections: PropTypes.array.isRequired,
  salesCenterData: PropTypes.object.isRequired,
  lotStatusConfig: PropTypes.object.isRequired
};

export default Map;
