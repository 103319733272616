class ControlUtils {
  /**
   * if directions active - will reset / deselect
   * if there is a selected place - will reset / deselect
   * if mapmode === surrounding area: set zoom to 14, center using global center
   * if mapmode === points of interest: set zoom to 10, center using global center
   * if mapmode === home sites: set zoom to salescenter lotmap number and, center using either:
   * global (if no map selected / single map) or specific lotmap (if multiple lotmap selected)
   *
   * @param {*} community
   * @param {*} directionsActive
   * @param {*} handleDirectionsReset
   * @param {*} map
   * @param {*} mapMode
   * @param {*} selectedPlace
   * @param {*} setSelectedPlace
   * @param {*} selectedMap: should be selected in:
   *  Map.js/changeMap(salesCenter) -> App.js/changeSelectedMap(SalesCenter);
   */
  static centerMap = (
    community,
    directionsActive,
    handleDirectionsReset,
    map,
    mapMode,
    selectedPlace,
    setSelectedPlace,
    selectedMap,
  ) => {
    const latLong = [];
    let lat = 0;
    let lng = 0;
    const len = community.lotMaps.length;

    if (community.lotMaps.length > 1) {
      community.lotMaps.forEach((lotMap) => {
        lat += lotMap.lotMapCenter[0];
        lng += lotMap.lotMapCenter[1];
      });
      lat = Number((lat / len)).toFixed(6);
      lng = Number((lng / len)).toFixed(6);
      latLong.push(lat);
      latLong.push(lng);
    } else {
      lat = community.lotMaps[0].lotMapCenter[0];
      lng = community.lotMaps[0].lotMapCenter[1];
      latLong.push(lat);
      latLong.push(lng);
    }

    if (directionsActive) {
      handleDirectionsReset();
    }
    if (selectedPlace) {
      setSelectedPlace(null);
    }
    if (mapMode === 'surrounding area') {
      map.setZoom(14);
      map.setCenter({
        lat: Number(latLong[0]), lng: Number(latLong[1])
      });
    }
    if (mapMode === 'points of interest') {
      map.setZoom(10);
      map.setCenter({
        lat: Number(latLong[0]), lng: Number(latLong[1])
      });
    }
    if (mapMode === 'home sites') {
      // only in case of multiple lots, when a sales center is selected
      if (community.lotMaps.length > 1 && selectedMap) {
        map.setZoom(selectedMap.lotMapZoom);
        map.setCenter({
          lat: selectedMap.lotMapCenter[0], lng: selectedMap.lotMapCenter[1]
        });
      } else if (community.lotMaps.length > 1 && !selectedMap) {
        // only in case of multiple lots, when a sales center is not selected
        map.setZoom(15);
        map.setCenter({
          lat: Number(latLong[0]), lng: Number(latLong[1])
        });
      } else {
        map.setZoom(community.lotMaps[0].lotMapZoom);
        map.setCenter({
          lat: community.lotMaps[0].lotMapCenter[0],
          lng: community.lotMaps[0].lotMapCenter[1]
        });
      }
    }
  }

  static rotateMap = (map) => {
    const heading = map.getHeading() || 0;
    map.setHeading(heading + 90);
  }

  static toggleTilt = (map) => {
    if (map.getTilt() === 0) {
      map.setTilt(45);
    } else {
      map.setTilt(0);
    }
  }

  static toggleMapType = (map) => {
    const mapType = map.getMapTypeId();
    let nextMapTypeId;
    if (mapType === 'roadmap') {
      nextMapTypeId = 'hybrid';
      map.setMapTypeId(nextMapTypeId);
    } else {
      nextMapTypeId = 'roadmap';
      map.setMapTypeId(nextMapTypeId);
    }

    return nextMapTypeId;
  }

  static toggleRoadNames = (map) => {
    const roadNamesHidden = map.styles.find((style) => style.featureType === 'road' && style.elementType === 'labels.text');
    if (roadNamesHidden) {
      map.setOptions({
        styles: [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
    } else {
      map.setOptions({
        styles: [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }]
          },
        ]
      });
    }
  }

  static waitForElement = (id, callback) => {
    const interval = setInterval(() => {
      if (document.getElementById(id)) {
        clearInterval(interval);
        callback();
      }
    }, 100);
  }

  static zoomIn = (map) => {
    const zoom = map.getZoom();
    map.setZoom(zoom + 1);
  }

  static zoomOut = (map) => {
    const zoom = map.getZoom();
    map.setZoom(zoom - 1);
  }
}

export default ControlUtils;
