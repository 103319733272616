

type IPlaceConfig = any;

class PlacesUtils {
  private placesService: google.maps.places.PlacesService;
  
  constructor(private readonly map:google.maps.Map) {
    this.placesService = new google.maps.places.PlacesService(map);
  }

  getPlace = (query: google.maps.places.FindPlaceFromQueryRequest["query"], type:string) => new Promise((resolve, reject) => {
    const request: google.maps.places.FindPlaceFromQueryRequest = {
      query,
      fields: ['id', 'geometry', 'name', 'place_id', 'photos', 'rating', 'types']
    };
    this.placesService.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const place = results![0] as any;
        place.type = type;
        place.location = place.geometry.location;
        place.placeID = place.place_id;
        place.id = place.place_id;
        resolve(place);
      }
      if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      }
      if (status
        === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
        setTimeout(() => {
          resolve(this.getPlace(query, type));
        }, 1000);
      } else {
        reject(new Error(`PlacesService error: ${status}`));
      }
    });
  });

  getPlaces = (request:any, type?:string) => new Promise((resolve, reject) => {
    this.placesService.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let places = [...results!];
        if (type) {
          places = places.map((place) => {
            const formattedPlace = { ...place } as any;
            formattedPlace.type = type;
            formattedPlace.location = formattedPlace.geometry.location;
            formattedPlace.placeID = formattedPlace.place_id;
            formattedPlace.id = formattedPlace.place_id;
            return formattedPlace;
          });
        }
        resolve(places);
      }
      if (status
        === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      }
      if (status
        === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
        setTimeout(() => {
          resolve(this.getPlaces(request, type));
        }, 1000);
      } else {
        reject(new Error(`PlacesService error: ${status}`));
      }
    });
  });

  getPlacesByConfig = async (config: any[], location: any, radius:number) => {
    const promises = config.map((configItem) =>
      this.getPlacesByKey(configItem, location, radius));
    let placesByConfig = await Promise.all([...promises]);
    const placesByConfigFmt = this.formatPlacesByConfig(config, placesByConfig);
    return placesByConfigFmt;
  }

  getPlacesByKey = async (configItem:any, location:any, radius:number) => {
    const promises = configItem.keys.map((key:string) => {
      const request = {
        location,
        radius,
        type: key
      };
      return this.getPlaces(request);
    });
    const placesByKey = await Promise.all([...promises]);
    return placesByKey;
  }

  formatPlacesByConfig = (config:any, placesByConfig:any) => {
    const result = {} as Record<string, any>;
    config.forEach((configItem:any, i:number) => {
      const typeField = configItem.name;
      const flattened = [].concat(...placesByConfig[i]);
      placesByConfig.forEach((placeConfig:any) => {
        placeConfig.forEach((item:any) => {
          item.map((itemExpand:any) => {
            const newItem = itemExpand;
            newItem.configType = typeField;
            return newItem;
          });
        });
      });
      const formattedPlaces = this.formatPlaces(flattened);
      result[configItem.name] = formattedPlaces;
    });
    return result;
  }

  formatPlaces = (places:any) => places.map((place:any) => ({
    id: place.place_id,
    location: place.geometry.location,
    name: place.name,
    placeID: place.place_id,
    photos: place.photos,
    rating: place.rating,
    type: place.configType,
  }));

  getPlaceById = (places:any[], id:string) => {
    const allPlaces = [] as any[];
    Object.entries(places).forEach((entry) => {
      entry[1].forEach((locationObject:any) => {
        allPlaces.push(locationObject);
      });
    });
    const result = allPlaces.find((place) =>
            place.id === id);
    return result;
  }
  findPlaceByMarker = (places:any[], marker:any) => {
    const allPlaces = [] as any[];
    Object.entries(places).forEach((entry) => {
      entry[1].forEach((locationObject:any) => {
        allPlaces.push(locationObject);
      });
    });
    const result = allPlaces.find((place) =>
      place.location === marker.latLng);
    return result;
  }
}

export default PlacesUtils;
