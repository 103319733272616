import React from 'react';
import PropTypes from 'prop-types';
import styles from './PhotoOverlay.module.css';
import PlaceHolder from '../../img/no-image-found.png';

const PhotoOverlay = ({ selectedPlace }) => (
  <div className={styles.photoOverlay}>
    <img
      className={styles.img}
      src={
        selectedPlace.photos
          ? selectedPlace.photos[0].getUrl()
          : PlaceHolder
        }
      alt="poi"
    />
  </div>
);

PhotoOverlay.propTypes = {
  selectedPlace: PropTypes.object.isRequired,
};

export default PhotoOverlay;
