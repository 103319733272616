import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ControlButton from '../../shared/ControlButton/ControlButton';
import Submenu from './Submenu/Submenu';
import VerticalNav from '../VerticalNav/VerticalNav';
import styles from './Menu.module.css';
import ControlUtils from '../../utils/Controls';
import cardsIcon from '../../img/cards-icon.svg';
import iconCommunity from '../../img/Icon_Community.svg';
import iconCommunityB from '../../img/Icon_Community_B.svg';
import markerIcon from '../../img/marker-icon.svg';
import satelliteIcon from '../../img/satellite-icon.svg';
import lotNumIcon from '../../img/lotnum-icon.svg';

class Menu extends Component {
  constructor(props) {
    super(props);
    const { map } = this.props;

    this.state = {
      directionsActive: false,
      icons: true,
      listType: 'list',
      mapType: map.getMapTypeId(),
      photoGalleryActive: false,
      productLineMenu: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapMode } = this.props;
    const { directionsActive, icons } = this.state;

    if (icons !== prevState.icons) {
      this.setIcons(icons);
    }
    if (mapMode !== prevProps.mapMode && directionsActive) {
      this.clearDirections();
    }
  }

  setIcons = (checked) => {
    const { markers, markerUtils } = this.props;
    if (markerUtils) {
      if (checked) {
        markerUtils.showMarkers('all');
      } else {
        markerUtils.hideMarkers(markers, 'all');
      }
    }
  }

  clearDirections = () => {
    const { directionsUtils } = this.props;
    directionsUtils.resetDirections();
    this.setState({ directionsActive: false });
  }

  toggleIcons = () => {
    const { pinsVsLots } = this.props;
    pinsVsLots();
    this.setState((prevState) => ({
      icons: !prevState.icons
    }));
  }

  toggleListType = () => {
    const { listType } = this.state;
    if (listType === 'list') {
      this.setState({ listType: 'card' });
    } else {
      this.setState({ listType: 'list' });
    }
  }

  toggleMapType = () => {
    const { map } = this.props;
    const mapType = ControlUtils.toggleMapType(map);
    this.setState({ mapType });
  }

  toggleDirectionsActive = () => {
    this.setState((prevState) => ({
      directionsActive: !prevState.directionsActive
    }));
  }

  togglePhotoGallery = () => {
    this.setState((prevState) => ({
      photoGalleryActive: !prevState.photoGalleryActive
    }));
  }

  handleDirectionsReset = () => {
    const { directionsUtils, map, selectedPlace } = this.props;

    directionsUtils.resetDirections();
    this.toggleDirectionsActive();
    map.setZoom(17);
    map.setCenter(selectedPlace.location);
  };

  homeSiteSelected = (subMenu, filterHolder) => {
    const {
      mapMode
    } = this.props;
    if (subMenu && !filterHolder) {
      if (mapMode === 'home sites') {
        return 'subMenuInactive';
      }
      return null;
    }
    if (!subMenu && filterHolder) {
      if (mapMode === 'home sites') {
        return null;
      }
      return 'filterHolderMove';
    }
    if (mapMode === 'home sites') {
      return null;
    }
    return 'inactive';
  }

  toggleProductLineMenu = () => {
    this.setState((prevState) => ({
      productLineMenu: !prevState.productLineMenu
    }));
  }

  mapText = () => {
    const { mapType } = this.state;

    if (mapType !== 'roadmap') {
      return 'Map View';
    }

    return 'satellite';
  }

  render(prevProps, prevState) {
    const {
      isBeingControlled,
      center,
      poiGalleryActive,
      disablePoiGallery,
      enablePoiGallery,
      collapseMenu,
      triggerMatterport,
      activeMatterport,
      community,
      directionsUtils,
      hidePlaces,
      mapMode,
      places,
      placesConfig,
      poi,
      poiConfig,
      roomId,
      selectedPlace,
      setSelectedPlace,
      showPlaces,
      socket,
      map,
      google,
      state,
      handleSelectedLotRID,
      clearSelectedLot,
      lotInfo,
      lotType,
      showOptionList,
      handleOptionList,
      handleShowAvailModel,
      showAvailModel,
      handleProductLine,
      activeProductLine,
      productLineText,
      newLotData,
      lotCollections,
      newModelData,
      activeCollections,
      salesCenterId,
      selectedCommunityRID,
      salesCenterData,
      lotStatusConfig,
      matterportConfig,
      selectedLotRID,
      splash,
      builderSelected,
      selectedMap,
      changeSelectedMap,
    } = this.props;
    const {
      directionsActive,
      listType,
      icons,
      mapType,
      photoGalleryActive,
      productLineMenu,
    } = this.state;

    return (
      <div className={styles.Menu}>
        {!isBeingControlled &&
            <div className={styles.VerticalNavWrapper}>
              <VerticalNav
                  community={community}
                  collapseMenu={collapseMenu}
                  directionsActive={directionsActive}
                  handleDirectionsReset={this.handleDirectionsReset}
                  map={map}
                  mapMode={mapMode}
                  salesCenterData={salesCenterData}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  selectedMap={selectedMap}
              />
            </div>
        }
        <div className={`${styles.collapseWrapper} ${collapseMenu ? styles.collapse : ''}`}>
          <Submenu
              isBeingControlled={isBeingControlled}
            center={center}
            poiGalleryActive={poiGalleryActive}
            disablePoiGallery={disablePoiGallery}
            enablePoiGallery={enablePoiGallery}
            listType={listType}
            community={community}
            directionsActive={directionsActive}
            directionsUtils={directionsUtils}
            handleDirectionsReset={this.handleDirectionsReset}
            hidePlaces={hidePlaces}
            mapMode={mapMode}
            places={places}
            placesConfig={placesConfig}
            poi={poi}
            poiConfig={poiConfig}
            roomId={roomId}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
            showPlaces={showPlaces}
            socket={socket}
            toggleDirectionsActive={this.toggleDirectionsActive}
            map={map}
            google={google}
            photoGalleryActive={photoGalleryActive}
            togglePhotoGallery={this.togglePhotoGallery}
            state={state}
            handleSelectedLotRID={handleSelectedLotRID}
            clearSelectedLot={clearSelectedLot}
            lotInfo={lotInfo}
            lotType={lotType}
            showOptionList={showOptionList}
            handleOptionList={handleOptionList}
            handleShowAvailModel={handleShowAvailModel}
            showAvailModel={showAvailModel}
            productLineMenu={productLineMenu}
            handleProductLine={handleProductLine}
            activeProductLine={activeProductLine}
            newLotData={newLotData}
            lotCollections={lotCollections}
            newModelData={newModelData}
            toggleProductLineMenu={this.toggleProductLineMenu}
            productLineText={productLineText}
            activeCollections={activeCollections}
            salesCenterId={salesCenterId}
            selectedCommunityRID={selectedCommunityRID}
            salesCenterData={salesCenterData}
            lotStatusConfig={lotStatusConfig}
            triggerMatterport={triggerMatterport}
            activeMatterport={activeMatterport}
            matterportConfig={matterportConfig}
            selectedLotRID={selectedLotRID}
            splash={splash}
            builderSelected={builderSelected}
            selectedMap={selectedMap}
            changeSelectedMap={changeSelectedMap}
          />
          {!isBeingControlled &&
              <div className={styles.buttonContainer}>
                <ControlButton
                    active={listType === 'card'}
                    alt="card view"
                    clickHandler={this.toggleListType}
                    icon={cardsIcon}
                    text={listType === 'list' ? 'card view' : 'list view'}
                />
                <ControlButton
                    active={mapMode === 'home sites' ? productLineMenu : true}
                    alt="collection"
                    clickHandler={() => this.toggleProductLineMenu()}
                    icon={salesCenterData.isActiveAdult ? iconCommunityB : iconCommunity}
                    text="collections"
                    disabled={!(activeCollections.length > 1)}
                />
                <ControlButton
                    active={mapType === 'hybrid'}
                    alt="satellite map"
                    clickHandler={() => this.toggleMapType()}
                    icon={satelliteIcon}
                    text={this.mapText()}
                />
                <ControlButton
                    active={icons}
                    alt="toggle map markers"
                    clickHandler={() => this.toggleIcons()}
                    icon={mapMode === 'home sites' ? lotNumIcon : markerIcon}
                    text={mapMode === 'home sites' ? 'lot numbers' : 'map icons'}
                />
              </div>
          }
        </div>
      </div>
    );
  }
}

Menu.defaultProps = {
  community: null,
  markers: null,
  poi: null,
  selectedPlace: null,
  showAvailModel: null,
  lotType: null,
  lotInfo: null,
};

Menu.propTypes = {
  center: PropTypes.object.isRequired,
  poiGalleryActive: PropTypes.bool.isRequired,
  disablePoiGallery: PropTypes.func.isRequired,
  enablePoiGallery: PropTypes.func.isRequired,
  collapseMenu: PropTypes.bool.isRequired,
  triggerMatterport: PropTypes.func.isRequired,
  activeMatterport: PropTypes.bool.isRequired,
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  community: PropTypes.object,
  directionsUtils: PropTypes.object.isRequired,
  markerUtils: PropTypes.object,
  hidePlaces: PropTypes.func.isRequired,
  mapMode: PropTypes.string.isRequired,
  places: PropTypes.object,
  placesConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  poi: PropTypes.object,
  poiConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired,
  showPlaces: PropTypes.func.isRequired,
  markers: PropTypes.arrayOf(PropTypes.object),
  roomId: PropTypes.string,
  state: PropTypes.object.isRequired,
  showAvailModel: PropTypes.string,
  socket: PropTypes.object,
  handleShowAvailModel: PropTypes.func.isRequired,
  handleOptionList: PropTypes.func.isRequired,
  showOptionList: PropTypes.bool.isRequired,
  lotType: PropTypes.string,
  lotInfo: PropTypes.object,
  clearSelectedLot: PropTypes.func.isRequired,
  handleSelectedLotRID: PropTypes.func.isRequired,
  pinsVsLots: PropTypes.func.isRequired,
  handleProductLine: PropTypes.func.isRequired,
  activeProductLine: PropTypes.string.isRequired,
  productLineText: PropTypes.string.isRequired,
  newLotData: PropTypes.object.isRequired,
  newModelData: PropTypes.object.isRequired,
  activeCollections: PropTypes.array.isRequired,
  salesCenterId: PropTypes.number.isRequired,
  salesCenterData: PropTypes.object.isRequired,
  lotStatusConfig: PropTypes.object.isRequired,
  selectedCommunityRID: PropTypes.string.isRequired,
  matterportConfig: PropTypes.object.isRequired
};

export default Menu;
