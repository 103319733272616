/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
const setAllData = (allData, activeCollections, salesCenterData) => {
  //for both models and lots
  return activeCollections
    .map((activeColl) => {
      const collection = salesCenterData.collections.find((c) =>
        c.communityRID === activeColl);
      const models = [...allData[activeColl]].map((model) => {
        const updatedModel = { ...model };
        updatedModel.communityRID = collection.communityRID;
        updatedModel.communityName = collection.name;
        return updatedModel;
      });
      return models;
    })
    .reduce((accum, currColl) => {
      currColl.forEach((model) => {
        accum.push(model);
      });
      return accum;
    }, []);
};

export function setInitialLotData(salesCenterData, newLotData, activeCollections, activeProductLine) {
  //  initialize with universal
  let initialLotData = [
    {
      commRID: true,
      productLine: 'all',
      data: setAllData(newLotData, activeCollections, salesCenterData)
    },
  ];

  //  create objects for possible collections
  salesCenterData.collections.forEach((c, i) => {
    const newObj = {};
    newObj.commRID = c.communityRID;
    newObj.productLine = c.name.toLowerCase();
    newObj.data = [...newLotData[c.communityRID]];
    newObj.key = i + 1;
    initialLotData.push(newObj);
  });

  //  filter based on active collections 
  initialLotData = initialLotData.filter((lotObj) => (
    lotObj.commRID === true || activeCollections.includes(lotObj.commRID)
  ));

  //  filter based on active productlines
  const activeLotData = [];
  initialLotData.forEach((obj) => {
    if (activeProductLine === obj.productLine) {
      activeLotData.push(...obj.data);
    }
  });

  return activeLotData;
}

export function setInitialModelData(salesCenterData, newModelData, activeCollections, selectedCommunityRID) {
  const initialModelData = [
    {
      productLine: 'all',
      commRID: 'all',
      data: setAllData(newModelData, activeCollections, salesCenterData)
    },
  ];

  salesCenterData.collections.forEach((c) => {
    const newObj = {};
    newObj.commRID = c.communityRID;
    newObj.productLine = c.name.toLowerCase();
    newObj.data = [...newModelData[c.communityRID]].map((model) => {
      const updatedModel = { ...model };
      updatedModel.communityRID = c.communityRID;
      updatedModel.communityName = c.name;
      return updatedModel;
    });
    newObj.community = salesCenterData.lotMapKey;
    initialModelData.push(newObj);
  });

  const activeModelData = [];

  initialModelData.forEach((obj) => {
    if (selectedCommunityRID === obj.commRID) {
      activeModelData.push(...obj.data);
    }
  });

  return activeModelData;
}
