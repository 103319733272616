import React from "react";
import styles from './EditMode.module.css';

function EditMode(props, state) {

  const {refreshEditModeInfo, overrideLotMapRotation, overrideLotMapBounds} = props;

  async function refreshRotation(event) {
    event.preventDefault();
    if (event.target.value) {
      refreshEditModeInfo({
        overrideLotMapRotation: event.target.value
      });
    }
  }

  async function refreshLotMapBounds(event) {
    const {google, overrideLotMapBounds} = props;
    event.preventDefault();
    if (overrideLotMapBounds && event.target.value) {
      const coordString = event.target.value.replace(' ', '');
      const coords = coordString.split(',');
      if (coords.length === 2) {
        var newCoord = new google.maps.LatLng(
          coords[0],
          coords[1]
        );
        let svgBounds = {};
        if (event.target.name === 'bottomLeft') {
          svgBounds = new google.maps.LatLngBounds(
            newCoord,
            overrideLotMapBounds.getNorthEast()
          );
        } else {
          svgBounds = new google.maps.LatLngBounds(
            overrideLotMapBounds.getSouthWest(),
            newCoord
          );
        }

        refreshEditModeInfo({
          overrideLotMapBounds: svgBounds
        });
      }
    }
  }

  async function updateLotMapBounds(event, corner, direction) {
    const {google, overrideLotMapBounds} = props;
    event.preventDefault();
    if (overrideLotMapBounds) {
      const southWest = overrideLotMapBounds.getSouthWest();
      let south = southWest.lat();
      let west = southWest.lng();
      const northEast = overrideLotMapBounds.getNorthEast();
      let north = northEast.lat();
      let east = northEast.lng();

      if (corner === 'ne') {
        if (direction === 'up') {
          north += 0.0001;
        } else if (direction === 'right') {
          east += 0.0001;
        } else if (direction === 'down') {
          north -= 0.0001;
        } else if (direction === 'left') {
          east -= 0.0001;
        }
      } else {
        if (direction === 'up') {
          south += 0.0001;
        } else if (direction === 'right') {
          west += 0.0001;
        } else if (direction === 'down') {
          south -= 0.0001;
        } else if (direction === 'left') {
          west -= 0.0001;
        }
      }

      let svgBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          south,
          west
        ),
        new google.maps.LatLng(
          north,
          east
        )
      );

      refreshEditModeInfo({
        overrideLotMapBounds: svgBounds
      });
    }
  }

  function getCoordinate(coords) {
    if (coords) {
      return `${coords.lat()},${coords.lng()}`
    }
    return "";
  }

  async function getLotRIDs() {
    const {lotCollections, communityTitle} = props;
    if (lotCollections) {
      const data = lotCollections.map((lot) => `<td>${lot.communityRID}</td><td>${lot.lotNum}</td><td>${lot.lotRID}</td>`)
        .join('</tr><tr>');
      const win = window.open('');
      if (win) {
        win.document.open();
        win.document.write(`<html>
<head>
<style>
table, td, th {border: 1px solid;padding: 10px;}
table {border-collapse: collapse;font-size: large}
</style>
<title>Lots: ${communityTitle}</title>
</head>
<body>
  <h1>Lots: ${communityTitle}</h1>
  <table>
    <tr><th>CommunityRID</th><th>LotNumber</th><th>LotRID</th></tr>
    <tr>${data}</tr>
  </table>
</body>`);
        win.document.close();
      }
    } else {
      alert('Lots not found!');
    }
  }

  async function getSaleCenterJSON() {

  }

  return(
    <div className={styles.editMode}>
      <div>
        <label htmlFor={'rotation'}>Rotation:</label>
        <input type='text' id='rotation' name='rotation' value={overrideLotMapRotation} onChange={(event)=> refreshRotation(event)} />
      </div>
      <div>
        <label htmlFor={'bottomLeft'}>Bottom Left:</label>
        <input type='text' id='bottomLeft' name='bottomLeft' value={getCoordinate(overrideLotMapBounds?.getSouthWest())} onChange={(event)=> refreshLotMapBounds(event)} />
        <button onClick={(event) => updateLotMapBounds(event,'sw','left')} type="button">Left</button>
        <button onClick={(event) => updateLotMapBounds(event,'sw','right')} type="button">Right</button>
        <button onClick={(event) => updateLotMapBounds(event,'sw','up')} type="button">Up</button>
        <button onClick={(event) => updateLotMapBounds(event,'sw','down')} type="button">Down</button>
      </div>
      <div>
        <label htmlFor={'topRight'}>Top Right:</label>
        <input type='text' id='topRight' name='topRight' value={getCoordinate(overrideLotMapBounds?.getNorthEast())} onChange={(event)=> refreshLotMapBounds(event)} />
        <button onClick={(event) => updateLotMapBounds(event,'ne','left')} type="button">Left</button>
        <button onClick={(event) => updateLotMapBounds(event,'ne','right')} type="button">Right</button>
        <button onClick={(event) => updateLotMapBounds(event,'ne','up')} type="button">Up</button>
        <button onClick={(event) => updateLotMapBounds(event,'ne','down')} type="button">Down</button>
      </div>
      <div>
        <label htmlFor={'center'}>Center:</label>
        <input type='text' id='center' name='center' value={getCoordinate(overrideLotMapBounds?.getCenter())} readOnly />
      </div>
      <div>
        <button onClick={() => getLotRIDs()} type="button">LotRIDs</button>
        {/*<button onClick={() => getSaleCenterJSON()} type="button">JSON</button>*/}
      </div>
    </div>
  )
}

export default EditMode;
