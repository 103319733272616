import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckBox.module.css';

const CheckBox = ({ active, clickHandler }) => (
  <label
    aria-label="checkBox"
    className={styles.checkBox}
  >
    <input type="checkbox" checked={active} onChange={clickHandler} />
    <span className={`${styles.checkmark}`} />
  </label>
);

CheckBox.propTypes = {
  active: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default CheckBox;
