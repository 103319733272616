import LotMapUtils from "../../utils/lotMapUtils";
import KovaUtils from "../../KovaUtils";
import DirectionsUtils from "../../utils/Directions";
import homeCardImg from "../../img/IMG_Home_Card.png";
import {fetchLots, getCommunityDetails} from "./Utils/utils";
import GlobalConfig from "../../utils/GlobalConfig";


export async function fetchCustomFields(lots: any, lotStatusConfig: any) {
   /*const {
        lotStatusConfig
    } = this.state;*/

    const quickMoveIn: any[] = [];
    const newArrayQMI: any[] = [];
    const noSlsOrd: any[] = [];
    const newKeys = Object.keys(lots);

    newKeys.forEach((key) => {
        lots[key].forEach((lot:any) => {
            newArrayQMI.push(lot);
        });
    });
    newArrayQMI.forEach((lot) => {
        const lotStatus = LotMapUtils.getLotStatus(lot, lotStatusConfig) as any;
        if (lotStatus[0] === 'QUICKMOVEIN' && lot.SlsOrd) {
            quickMoveIn.push(lot);
        }
        if (lotStatus[0] === 'QUICKMOVEIN' && !lot.SlsOrd) {
            noSlsOrd.push(lot);
        }
    });

    const customFieldsBool: boolean[] = [];
    const slsRIDs: number[] = [];
    const models: any[] = [];

    const firstStringPromises = quickMoveIn.map((lot) => {
        slsRIDs.push(lot.SlsOrd.SlsOrdRID);
        customFieldsBool.push(false);
        return KovaUtils.fetchCustomInfo(lot.SlsOrd.SlsOrdRID);
    });

    await Promise.all(firstStringPromises)
        .then((response) => {
            response.forEach((res, idx) => {
                const ModelinQuestion = quickMoveIn.find((model) =>
                    model.SlsOrd.SlsOrdRID === slsRIDs[idx]);
                models.push(ModelinQuestion);
                const resp = response[idx];
                resp.forEach((field:any) => {
                    if (field.FieldID === 'MoneyDSHprice1') {
                        ModelinQuestion.QuickPrice = field.MoneyValue;
                    }
                    if (field.FieldID === 'IntDSHsqft1') {
                        ModelinQuestion.QuickSQF = field.IntValue;
                    }
                    if (field.FieldID === 'IntDSHbed1') {
                        ModelinQuestion.QuickBeds = field.IntValue;
                    }
                    if (field.FieldID === 'DecimalDSHbath1') {
                        ModelinQuestion.QuickBaths = field.DecimalValue;
                    }
                });

                if (ModelinQuestion && ModelinQuestion.QuickPrice
                    && ModelinQuestion.QuickSQF
                    && ModelinQuestion.QuickBeds
                    && ModelinQuestion.QuickBaths) {
                    customFieldsBool[idx] = true;
                }
            });
        });

    const secondArray: any[] = [];

    models.forEach((model, idx) => {
        if (!customFieldsBool[idx]) {
            secondArray.push(model);
        }
    });
    if (secondArray.length > 0) {

        let slsOrdRIDToMarketHome = new Map<number, any>();

        //newKeys seems to be communityrids
        for(let communityRID of newKeys){
            let marketHomes = await KovaUtils.fetchCommunityMarketHomes(communityRID);
            marketHomes.forEach((marketHome: any) => {
                slsOrdRIDToMarketHome.set(marketHome.SlsOrdRID, marketHome);
            });
        }

        for (let lot of secondArray) {
            let marketHome = lot.SlsOrd && slsOrdRIDToMarketHome.get(lot.SlsOrd.SlsOrdRID);
            let modelInQuestion = lot.SlsOrd && quickMoveIn.find((model) => model.SlsOrd.SlsOrdRID === lot.SlsOrd.SlsOrdRID);
            if(marketHome && modelInQuestion){
                modelInQuestion.QuickSQF = marketHome.Sqf;
                modelInQuestion.QuickBeds = marketHome.NumBedrooms;
                modelInQuestion.QuickBaths = marketHome.NumBaths;
                modelInQuestion.QuickPrice = marketHome.Price;
            }
            if (modelInQuestion && !modelInQuestion.QuickSQF) {
                modelInQuestion.QuickSQF = 'N/A';
            }
            if (modelInQuestion && !modelInQuestion.QuickBeds) {
                modelInQuestion.QuickBeds = 'N/A';
            }
            if (modelInQuestion && !modelInQuestion.QuickBaths) {
                modelInQuestion.QuickBaths = 'N/A';
            }
            if (modelInQuestion && !modelInQuestion.QuickPrice) {
                modelInQuestion.QuickPrice = 'N/A';
            }
        }
    }

    let lotObj = null;
    noSlsOrd.forEach((lot) => {
        lotObj = lot;
        lotObj.QuickSQF = 'N/A';
        lotObj.QuickBeds = 'N/A';
        lotObj.QuickBaths = 'N/A';
        lotObj.QuickPrice = 'N/A';
        quickMoveIn.push(lotObj);
    });
    const lotKeys = Object.keys(lots);
    const newLots: any = {};
    lotKeys.forEach((key) => {
        newLots[key] = [];
        lots[key].forEach((lot:any) => {
            let matched = false;
            quickMoveIn.forEach((QMI) => {
                if (QMI.Name === lot.Name) {
                    newLots[key].push(QMI);
                    matched = true;
                }
            });
            if (!matched) {
                newLots[key].push(lot);
            }
        });
    });
    return newLots;
}

/**
 * takes the lotmaps from the sales center object
 * and finds the center. returns the latLong object
 *
 * @param {salescenterData object} SSData
 */
export function getCenterLatLng(SSData: any) {
    const latLong = [];
    let lat = 0;
    let lng = 0;
    const len = SSData.lotMaps.length;

    if (SSData.lotMaps.length > 1) {
        SSData.lotMaps.forEach((lotMap:any) => {
            lat += lotMap.lotMapCenter[0];
            lng += lotMap.lotMapCenter[1];
        });
        lat = lat / len;
        lng = lng / len;
        latLong.push(lat);
        latLong.push(lng);
    } else {
        [lat, lng] = [SSData.lotMaps[0].lotMapCenter[0],
            SSData.lotMaps[0].lotMapCenter[1]];

        latLong.push(lat);
        latLong.push(lng);
    }
    return (latLong);
}

async function fetchAllCollectionLots(salesCenterData: any) {
    const newLotData = {} as any;

    const lotExtraDataPromises = salesCenterData.collections.map((c:any) => {
        return KovaUtils.fetchExtraLotData(c.communityRID);
    });

    let lotExtraDataResponse = await Promise.all(lotExtraDataPromises);

    const lotPromises = salesCenterData.collections.map((c:any) => {
        return KovaUtils.fetchCommunityLots(c.communityRID);
    });

    await Promise.all(lotPromises)
        .then((response) => {
            response.forEach((res, i) => {

                for(let lot of response[i])
                {
                    let lotExtraData = lotExtraDataResponse[i].find((l : any) => l.LotRID === lot.LotRID);
                    if(lotExtraData){
                        lot.Handing = lotExtraData.Handing;
                    }
                }

                newLotData[
                    salesCenterData.collections[i].communityRID
                    ] = response[i];
            });
        });

    return newLotData;
}
export async function initializeLotData(salesCenterData: any, lotStatusConfig: any, activeCollections: any) {
    const auxLotData = await fetchLots(salesCenterData.lotMaps[0].auxLotMapCollections);
    let newLotData = await fetchAllCollectionLots(salesCenterData);
    newLotData = await fetchCustomFields(newLotData, lotStatusConfig);

    const communityDetails = await getCommunityDetails(salesCenterData);

    const lotObjects = formatLotsForMap(newLotData, salesCenterData, activeCollections, lotStatusConfig);
    // lotCollections is used in Map.js
    return { lotObjects, communityDetails, auxLotData };

}
let amenityCounter = 1;
export function initMap(salesCenterData: any,
                        google: any,
                        unrealUrl: string | null | undefined, checkMapClick: (google: any, position: { lat?: number, lng?: number}) => void,
                        mapTypeId: string | undefined)  {
    //const { salesCenterData } = this.state;
    //const { google } = this;
    const latLong = getCenterLatLng(salesCenterData);

    const center = new google.maps.LatLng(
        latLong[0], latLong[1]
    );

    const map = new google.maps.Map(
        document.getElementById('map'), {
            zoom: 14,
            center: center,
            mapTypeControl: false,
            mapTypeId: mapTypeId || 'roadmap',
            rotateControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
            styles: [
                {
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'off' }]
                }
            ],
            gestureHandling: GlobalConfig.get(GlobalConfig.Key.MAP_GESTURE_HANDLING),
        }
    );

    const position: { lat?: number, lng?: number} = {};
    map.addListener('click', (event: any) => {
        position.lat = parseFloat(event.latLng.lat());
        position.lng = parseFloat(event.latLng.lng());
        checkMapClick(google, position);
    });
    if (unrealUrl !== null && unrealUrl !== undefined) {
        map.addListener('idle', () => {

            const zoom = map.getZoom();
            const center = map.getCenter();

            const fragObject = {lat: center.lat(), lng: center.lng(), zoom: zoom};
            const currentFragment = window.location.hash;
            const mapFragment = 'map=' + encodeURIComponent(JSON.stringify(fragObject));

            const fragment = currentFragment.length > 2 ? (currentFragment + "&" + mapFragment) : ("#" + mapFragment);

            console.log(fragment);
            fetch(`${unrealUrl}/MediaCanvas`, {
                body: JSON.stringify({
                    "Id": "Amenity" + (amenityCounter++),
                    "Command": "Browse",
                    "Url": `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${fragment}`
                }),
                method: 'post'
            }).catch(err => {});
            /*fetch(`${unrealUrl}/WebsiteController`, {
                body: JSON.stringify({website: {
                    address: `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${fragment}`}
                }),
                method: 'post'
            }).catch(err => {
            });
*/
        })
    }
    const directionsUtils = new DirectionsUtils(google, map);

    return { directionsUtils, map, center };

    //this.setState({ mapInitialized: true });
};

export function formatLotsForMap(lotData: any, salesCenterData: any, activeCollections: any, lotStatusConfig: any) {
   // const { salesCenterData, activeCollections, lotStatusConfig } = this.state;

    const initialLotData: any[] = [];

    const lotDataObj: any = {};
    salesCenterData.collections.forEach((comObj:any) => {
        lotDataObj[comObj.communityRID] = [];
        const newLotObject: any = {};
        newLotObject.commRID = comObj.communityRID;
        newLotObject.productLine = comObj.name.toLowerCase();
        newLotObject.data = (lotData
                && [...lotData[comObj.communityRID]])
            || [];
        newLotObject.enabled = null;

        if (!activeCollections.includes(comObj.communityRID)) {
            newLotObject.enabled = false;
        } else {
            newLotObject.enabled = true;
        }

        initialLotData.push(newLotObject);
    });

    const lotObject: any[] = [];
    const descriptionExceptions = ['do not use', 'model home'];
    initialLotData.forEach((collection) => {
        collection.data.forEach((lot: any, index:number) => {
            const lotStatus = LotMapUtils.getLotStatus(lot, lotStatusConfig);
            if (lotStatus === undefined) throw "LotStatus returned undefined";
            const obj: any = {};

            const mapNumber = lot.Name.split(',')[0];
            obj.mapNumber = Number(mapNumber.split('0')[1]);
            obj.lotNum = Number(lot.Description.split('Lot ')[1]);
            if (obj.lotNum === 0) {
                return;
            }
            obj.id = lot.LotRID;
            obj.blockNum = !descriptionExceptions.includes(lot.Description.toLowerCase()) ? Number(lot.Description.split(', ')[1].split('Block ')[1]) : 'N/A';
            obj.type = lot.Type;
            obj.info = lot.Status;
            obj.index = index;
            if (isNaN(obj.blockNum)) {
                obj.name = `Homesite ${obj.lotNum}`;
            } else {
                obj.name = `Block ${obj.blockNum} - Homesite ${obj.lotNum}`;
            }

            //end all submenu state//

            if (lotStatus[0] === 'QUICKMOVEIN' && !lot.SlsOrd) {
                obj.noSlsOrd = true;
            }
            if (lotStatus[0] === 'QUICKMOVEIN' && lot.SlsOrd) {
                obj.SlsOrdRID = lot.SlsOrd.SlsOrdRID;
            }
            obj.communityRID = collection.commRID;
            if (collection.productLine) {
                obj.productLine = collection.productLine;
            }

            if(GlobalConfig.get(GlobalConfig.Key.SHOW_LOT_PREMIUMS)) {
                obj.lotPremium = lot.LotPremium;
                obj.Handing = lot.Handing;
            }

            obj.lotRID = lot.LotRID;
            obj.selector = `#_${lot.LotRID}`;
            obj.textSelector = `#L_${lot.LotRID}`;
            let numText = lot.Description.split('Lot ')[1];
            if (numText && lot.Description && numText.includes('A')) {
                numText = numText.split('A')[0];
            }
            // Harris Doyle lot text hack
            if (numText && lot.Description.includes('-')) {
                let splitResult = numText.split('-');
                if (splitResult && splitResult.length === 2) {
                    [, numText] = splitResult;
                }
            }
            obj.lotNum = Number(numText);
            obj.type = lot.Type;
            obj.info = lot.Status;
            obj.index = index;
            obj.Color = collection.enabled ? lotStatus[1].color : '#cecece';
            obj.highlightColor = lotStatus[1].color;
            obj.blockNum = !descriptionExceptions.includes(lot.Description.toLowerCase()) ? Number(lot.Description.split(', ')[1].split('Block ')[1]) : 'N/A';
            if (isNaN(obj.blockNum) || obj.blockNum === 'N/A') {
                obj.name = `Homesite ${obj.lotNum}`;
            } else {
                obj.name = `Block ${obj.blockNum} - Homesite ${obj.lotNum}`;
            }
            obj.photos = [];
            obj.photos.push(homeCardImg);
            obj.AvailableModels = lot.AvailableModels;
            obj.enabled = collection.enabled;
            [obj.status] = lotStatus;
            if (lot.QuickPrice) {
                obj.QuickPrice = lot.QuickPrice;
                obj.QuickBeds = lot.QuickBeds;
                obj.QuickBaths = lot.QuickBaths;
                obj.QuickSQF = lot.QuickSQF;
            }
            if (lot.Model) {
                obj.model = lot.Model;
            }
            if (obj.lotNum) {
                lotObject.push(obj);
            }
            lotDataObj[collection.commRID].push(obj);
        });
    });
    return [lotObject, lotDataObj];
}
