import React from 'react';
import PropTypes from 'prop-types';
import styles from './ControlButton.module.css';

const ControlButton = ({
  active,
  alt,
  clickHandler,
  icon,
  text,
  disabled
}) => (
  <button className={`${styles.ControlButton} ${disabled && styles.disabled}`} type="button" onClick={clickHandler} disabled={disabled}>
    <img src={icon} alt={alt} />
    <span>{text}</span>
  </button>
);

ControlButton.defaultProps = {
  active: false,
  text: '',
  disabled: false
};

ControlButton.propTypes = {
  active: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool
};

export default ControlButton;
