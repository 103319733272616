import React from 'react';
import PropTypes from 'prop-types';
import styles from './VerticalNav.module.css';
import ControlUtils from '../../utils/Controls';
import plusIcon from '../../img/plus_icon.svg';
import plusIconB from '../../img/plus_icon_B.svg';
import centerIcon from '../../img/center_icon.svg';
import centerIconB from '../../img/center_icon_B.svg';
import minusIcon from '../../img/minus_icon.svg';
import minusIconB from '../../img/minus_icon_B.svg';

const VerticalNav = ({
  community,
  collapseMenu,
  directionsActive,
  handleDirectionsReset,
  map,
  mapMode,
  salesCenterData,
  selectedPlace,
  setSelectedPlace,
  selectedMap,
}) => {
  const buttonConfig = [
    {
      key: 1,
      icon: salesCenterData.isActiveAdult ? plusIconB : plusIcon,
      alt: 'zoom-in',
      clickHandler: () => ControlUtils.zoomIn(map),
      handleKeyPress: (event) => {
        if (event.key === 'Enter') {
          ControlUtils.zoomIn(map);
        }
      }
    },
    {
      key: 2,
      icon: salesCenterData.isActiveAdult ? centerIconB : centerIcon,
      alt: 'center',
      clickHandler: () =>
        ControlUtils.centerMap(
          community,
          directionsActive,
          handleDirectionsReset,
          map,
          mapMode,
          selectedPlace,
          setSelectedPlace,
          selectedMap,
        ),
      handleKeyPress: (event) => {
        if (event.key === 'Enter') {
          ControlUtils.centerMap(
            community,
            directionsActive,
            handleDirectionsReset,
            map,
            mapMode,
            selectedPlace,
            setSelectedPlace,
            selectedMap,
          );
        }
      }
    },
    {
      key: 3,
      icon: salesCenterData.isActiveAdult ? minusIconB : minusIcon,
      alt: 'zoom-out',
      clickHandler: () => ControlUtils.zoomOut(map),
      handleKeyPress: (event) => {
        if (event.key === 'Enter') {
          ControlUtils.zoomOut(map);
        }
      },
    },
  ];

  const navButtons = buttonConfig.map((button) => (
    <button
      key={button.key}
      onClick={button.clickHandler}
      onKeyPress={button.handleKeyPress}
      type="button"
    >
      <img src={button.icon} alt={button.alt} />
    </button>
  ));

  return (
    <div className={`${styles.VerticalNav} ${collapseMenu ? styles.collapse : ''}`}>
      {navButtons}
    </div>
  );
};

VerticalNav.defaultProps = {
  selectedPlace: null,
  selectedMap: null
};

VerticalNav.propTypes = {
  collapseMenu: PropTypes.bool.isRequired,
  community: PropTypes.object.isRequired,
  directionsActive: PropTypes.bool.isRequired,
  handleDirectionsReset: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  mapMode: PropTypes.string.isRequired,
  selectedMap: PropTypes.object,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired
};

export default VerticalNav;
