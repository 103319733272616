import React, { Component } from 'react';
import queryString from 'query-string';
import { fetchJSONFile, fetchLots, getCommunityDetails } from './Utils/utils';
import CustomOverlayUtils from '../../utils/CustomOverlay';
import DirectionsUtils from '../../utils/Directions';
import KovaUtils from '../../KovaUtils';
import MarkerUtils from '../../utils/Marker';
import PlacesUtils from '../../utils/Places';
import Socket from '../../utils/Socket';
import PhotoOverlay from '../PhotoOverlay/PhotoOverlay';
import Map from '../Map/Map';
import Menu from '../Menu/Menu';
import styles from './App.module.css';
import Legend from '../Legend/Legend';
import LotMapUtils from '../../utils/lotMapUtils';
import MapModeNav from '../MapModeNav';
import BackButton from '../../img/Button_Back.png';
import LoadingPage from '../LoadingPage/LoadingPage';
import homeCardImg from '../../img/IMG_Home_Card.png';
import PrintPDF from '../PrintPDF/PrintPDF';
import EditMode from './Utils/EditMode';
import { useMediaQuery } from 'usehooks-ts'


// import MockSalesCenter from '../../utils/GoodallSalesCenter.json';
import { formatModelData } from './Utils/format';
import GlobalConfig, {getEnabledMapModes} from "../../utils/GlobalConfig";
import {fetchCustomFields, formatLotsForMap, getCenterLatLng, initializeLotData, initMap} from "./appLogic";
import {addEditMarker, updateEditModeMarkers, MarkerIdType} from "../../utils/EditModeMarkerMove";
import {setGoogleAnalytics} from "../../utils/GoogleAnalytics";
import {setHubSpotAnalytics} from "../../utils/HubSpotAnalytics";
const MapModeHomeSites = "home sites";

class App extends Component {
  constructor(props) {
    super(props);

    const {mobileMode, mapsAuth} = props;

    this.state = {
      communityDetails: null,
      communityRID: null,
      poiGalleryActive: false,
      collapseMenu: mobileMode,
      collapseMapMode: mobileMode,
      dataInitialized: false,
      mapInitialized: false,
      markers: [],
      places: null,
      poi: null,
      roomId: null,
      scriptLoaded: false,
      state: {
        id: null,
        name: null
      },
      streetVIconClicked: false,
      mapClicked: false,
      pinsActive: true,
      selectedLotRID: '',
      lotInfo: null,
      lotType: null,
      showOptionList: false,
      showAvailModel: null,
      newHomeSelectedLotRID: null,
      activeProductLine: 'all',
      productLineText: '',
      triggerPrint: false,
      activeMatterport: false,
      newLotData: null,
      newModelData: null,
      activeCollections: null,
      amenityCenters: [],
      blacklist: null,
      salesCenterId: null,
      selectedCommunityRID: 'all',

      salesCenterData: null,
      communityTitle: null,
      lotStatusConfig: null,
      lotMapArray: null,
      auxLotData: null,
      matterportConfig: null,
      splash: null,
      builderSelected: null,
      selectedMap: null,
      SecondaryFilteredLots: null,


      loaded: false,
      svgLoaded: false,
      pinsLoaded: false,

      lotCollections: [],

      poiInclusion: null,
      communityInclusion: null,
      stateInclusion: null,
      mobileMode:mobileMode,
      editMode:props.editMode,
      overrideLotMapRotation:'',
      overrideLotMapBounds: null
    };

    // to change the color of the menu items, change colors
    // will only change text color, need list of colors from client
    this.placesConfig = [
      { name: 'medical', keys: ['hospital'], color: 'rgba(30, 138, 220, 1)' },
      { name: 'grocery', keys: ['grocery_or_supermarket'], color: 'rgba(9, 154, 119, 1)' },
      { name: 'shopping', keys: ['shopping_mall'], color: 'rgba(201, 30, 220, 1)' },
      { name: 'dining', keys: ['restaurant'], color: 'rgba(230, 135, 62, 1)' },
      { name: 'transportation', keys: ['airport', 'train_station'], color: 'rgba(230, 62, 62, 1)' },
    ];
    this.poiConfig = [
      { name: 'state', color: 'rgba(30, 138, 220, 1)' },
      { name: 'community', color: 'rgba(230, 135, 62, 1)' }
    ];
    this.scripts = [
      `https://maps.googleapis.com/maps/api/js?key=${mapsAuth}&libraries=places`,
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
    ];
    this.socket = null;
    this.mapFilterObject = {
      'home sites': {
        mapTypeCategories: ['Pins', 'Lot Numbers'],
        mapPresentation: ['icons', 'road names', 'block numbers'],
        mapFilterStates: {
          icons: true,
          'road names': true,
          'block numbers': true
        },
      },
      community: {
        mapTypeCategories: ['satellite', 'roadmap'],
        mapPresentation: ['icons', 'road names', 'communities'],
        mapFilterStates: {
          icons: true,
          'road names': true,
          communities: true
        },
      },
      'surrounding area': {
        mapTypeCategories: ['satellite', 'roadmap'],
        mapPresentation: ['icons', 'road names', 'communities'],
        mapFilterStates: {
          icons: true,
          'road names': true,
          communities: true
        },
      },
      'points of interest': {
        mapTypeCategories: ['satellite', 'roadmap'],
        mapPresentation: ['icons', 'road names', 'communities'],
        mapFilterStates: {
          icons: true,
          'road names': true,
          communities: true
        },
      }
    };
  }

  componentDidMount() {
    setGoogleAnalytics();
    setHubSpotAnalytics();
    this.initSocket();
    this.retrieveS3();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dataInitialized,
      mapInitialized,
      scriptLoaded,
      communityRID,
      salesCenterData,
      newModelData,
      svgLoaded,
      pinsLoaded,
      overrideLotMapRotation,
      overrideLotMapBounds
    } = this.state;

    if (salesCenterData && !prevState.salesCenterData) {
      this.filterCollections();
    }
    if (communityRID && !prevState.communityRID) {
      this.loadScripts(this.scripts);
    }
    if (scriptLoaded && !prevState.scriptLoaded) {
      this.initMap();
    }
    if (mapInitialized && !prevState.mapInitialized) {
      this.initData();
    }

    if (dataInitialized && !prevState.dataInitialized) {
      this.initMapMode();
    }
    if (prevProps.mapMode !== this.props.mapMode) {
      this.initMapMode();
    }
    if (newModelData && !prevState.newModelData) {
      this.excludeModels();
      this.checkModelParam();
      // check print //
      // setTimeout(() => {
      //   this.setState({ triggerPrint: !this.state.triggerPrint });
      // }, 10000);
    }
    if (svgLoaded !== prevState.svgLoaded
      || pinsLoaded !== prevState.pinsLoaded) {
      this.changeLoadingStatus();
    }

    if (overrideLotMapRotation !== prevState.overrideLotMapRotation ||
        overrideLotMapBounds !== prevState.overrideLotMapBounds ||
        overrideLotMapBounds?.bottomLeft !== prevState.overrideLotMapBounds?.bottomLeft ||
        overrideLotMapBounds?.topRight !== prevState.overrideLotMapBounds?.topRight
        ) {
      this.applyLotmapOverlay();
    }
    //if (placeid && dataInitialized && !prevState.dataInitialized) {
      //places.dining[2]
      //const place = salesCenterData;

    //}
  }

  loadScripts = async (scripts) => {
    try {
      const promises = scripts.map((script) => this.generateScript(script));
      await Promise.all([...promises]);
      this.handleScriptLoaded();

    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  filterCollections = () => {
    const { salesCenterData } = this.state;

    const selectedRID = salesCenterData.collections[0].communityRID;
    const communityTitle = salesCenterData.locationName;
    const productLineText = salesCenterData.locationName;

    this.setState({
      activeCollections: salesCenterData.collections.map((c) => c.communityRID),
      communityRID: selectedRID,
      communityTitle,
      productLineText
    });
  }

  /**
   * generates the scripts to be loaded onto the page, Google is on of them
   * as an example. Applies it to the windo
   *
   * @param {*} src
   */
  generateScript = (src) => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      resolve('loaded');
    };
    script.onerror = () => {
      reject(new Error(`Error loading script: ${src}`));
    };
    document.body.appendChild(script);
  });

  /**
   * uses the scripts that are applied to the window. and sets
   * scriptloaded to true.
   *
   * scriptLoaded initiates initmap function and is checked before the page
   * is rendered
   */
  handleScriptLoaded = () => {
    this.google = window.google;
    this.markerClusterer = window.MarkerClusterer;
    this.setState({ scriptLoaded: true });
  }

  /**
   * initiates the socket that communicates with Electron via
   * server events.
   *
   * the events are;
   * 'toggle menu'
   *
   * 'map mode' which controls the map mode
   *
   * 'pring hsm' - event for printing the homesite map
   *
   * 'view matterport' - event for viewing the matterport
   */
  initSocket = () => {
    const query = queryString.parse(window.location.search);
    const roomId = query.RoomId;
    if (roomId) {
      const config = {
        listeners: [
          {
            name: 'toggle menu',
            eventHandler: this.toggleCollapseMenu
          },
          {
            name: 'toggle mapmode',
            eventHandler: this.toggleCollapseMapMode
          },
          {
            name: 'map mode',
            eventHandler: this.setMapMode
          },
          {
            name: 'print hsm',
            eventHandler: this.listenForPrint
          },
          {
            name: 'view matterport',
            eventHandler: this.listenForMatterport
          },
        ]
      };

      const socket = new Socket(config);
      socket.init(roomId, GlobalConfig.get(GlobalConfig.Key.SOCKET_URL));
      socket.listen();

      this.socket = socket;
      this.setState({ roomId });
    }
  }

  /**
   * uses the utils file to format models.
   *
   * @param {*} models
   */
  formatModelData = (models) => {
    return formatModelData(models);
  }

  initMap = () => {

    const unrealUrl = this.props.unrealUrl;
    const mapTypeId = this.props.mapTypeId;

    const { map, directionsUtils, center } = initMap(this.state.salesCenterData, this.google, unrealUrl, this.checkMapClick, mapTypeId);
    this.map = map;
    this.center = center;
    this.directionsUtils = directionsUtils;
    this.setState({mapInitialized: true});
  }
  initData = async () => {
    const { salesCenterData } = this.state;
    try {
      const { lotObjects, communityDetails, auxLotData } = await initializeLotData(salesCenterData, this.state.lotStatusConfig, this.state.activeCollections);
      const lotCollections = lotObjects[0];
      const newLotData = lotObjects[1];

      let newModelData = await this.fetchAllCollectionModels();
      newModelData = this.formatModelData(newModelData);
      if (this.props.mapMode !== 'home sites') {
        await this.initalizePlacesData();
      } else {
        const poi = null;
        const places = null;
        const state = {
          id: null,
          name: null
        };
        this.setState({
          places,
          poi,
          state
        });
      }
      this.setState({
        auxLotData,
        communityDetails,
        dataInitialized: true,
        newLotData,
        newModelData,
        lotCollections

      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      //document.body.innerHTML = "<p>" + JSON.stringify(err);

      //window.alert(`error initializing data: ${JSON.stringify(err)}`);
      // TODO: Replace console log with Alert/Message component
      // eslint-disable-next-line no-console
      console.error(`error initializing data: ${err}`);
    }
  }



  excludeModels = () => {
    const { newModelData, salesCenterData } = this.state;

    const keys = Object.keys(newModelData);
    let listToExclude = [];
    if (salesCenterData.excludedModels) {
      listToExclude = salesCenterData.excludedModels;
    }

    //  build list to exclude
    keys.forEach((key) => {
      newModelData[key].forEach((model) => {
        listToExclude.forEach((exclusionKey) => {
          if (model.Name.includes(exclusionKey)) {
            listToExclude.push(model);
          }
        });
      });
    });

    //  iterate through models and remove excluded models, reconstruct object

    const newModelObjectArray = {};
    keys.forEach((key) => {
      const newArray = [];
      newModelData[key].forEach((model) => {
        if (!listToExclude.includes(model)) {
          newArray.push(model);
        }
      });
      newModelObjectArray[key] = newArray;
    });
    this.setState({ newModelData: newModelObjectArray });
  }



  retrieveS3 = async () => {
    let salesCenterId = Number(
      queryString.parse(window.location.search).SaleCenterId
    );
    if (!salesCenterId) {
      salesCenterId = 1;
    }
    // eslint-disable-next-line max-len
    let builderSelected = queryString.parse(window.location.search).BuilderSelected || false;

    builderSelected = (builderSelected === 'true');
    try {
      const [
        blacklist,
        lotStatusConfig,
        matterportConfig,
        salesCenterConfig,
      ] = await Promise.all([
        fetchJSONFile('amenityExclusionList.json'),
        fetchJSONFile('lotStatus.json'),
        fetchJSONFile('matterport.json'),
        fetchJSONFile('salesCenter.json'),
      ]);

      let [
        poiInclusion,
        communityInclusion,
        stateInclusion
      ] = await Promise.all([
        fetchJSONFile('POISurroundingArea.json'),
        fetchJSONFile('POICommunity.json'),
        fetchJSONFile('POIState.json')
      ]);

      const salesCenterData = salesCenterConfig.find((salesCenter) =>
        salesCenter.salesCenterId === salesCenterId);

      const splash = salesCenterData.altBuilders.length;

      if (!salesCenterData.altBuilders.length) {
        builderSelected = false;
      }

      poiInclusion = poiInclusion.find((POIs) => POIs.salesCenter === salesCenterId);

      // if no matching community info
      const inclusionObject = communityInclusion.find((CommPOIs) => CommPOIs.communityName === salesCenterData.locationName);

      if (inclusionObject) {
        communityInclusion = inclusionObject;
      } else {
        const replacementInclusionObj = {};
        replacementInclusionObj.communityName = salesCenterData.locationName;
        replacementInclusionObj.communityPOI = [];
        communityInclusion = replacementInclusionObj;
      }

      stateInclusion = stateInclusion.find((StatePOIs) => StatePOIs.stateKey === salesCenterData.stateKey);

      if (salesCenterData.isActiveAdult) {
        document.documentElement.style.setProperty('--color-a', '#621244');
        document.documentElement.style.setProperty('--color-a-light', '#F2E2EC');
        document.documentElement.style.setProperty('--color-b', '#50A3A2');
        document.documentElement.style.setProperty('--font-family-a', 'Optima');
      }

      this.setState({
        blacklist,
        lotStatusConfig,
        matterportConfig,
        salesCenterData,
        salesCenterId,
        splash,
        builderSelected,
        poiInclusion,
        communityInclusion,
        stateInclusion
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  getPlaces = async () => {
    const { blacklist, poiInclusion } = this.state;

    const places = await this.PlacesUtils.getPlacesByConfig(this.placesConfig, this.center, '8000');
    const initInclusion = poiInclusion.poi;

    let locations = [];

    locations = await Promise.all(initInclusion.map((location) =>
      this.PlacesUtils.getPlace(location.name, location.category)));

    Object.keys(places).forEach((key) => {
      blacklist.forEach((listLocation) => {
        places[key] = places[key].filter((place) =>
          place.name !== listLocation.Location);
      });
    });

    locations.forEach((location) => {
      places[location.type].unshift(location);
    });

    return places;
  }

  getPoi = async () => {
    const {
      communityInclusion,
      stateInclusion,
    } = this.state;

    const poi = {};
    const statePromises = [];
    const communityPromises = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const location of communityInclusion.communityPOI) {
      communityPromises.push(this.PlacesUtils.getPlace(location.name, 'community'));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const location of stateInclusion.list) {
      statePromises.push(this.PlacesUtils.getPlace(location.name, 'state'));
    }
    poi.community = await Promise.all(communityPromises);
    poi.state = await Promise.all(statePromises);
    const request = { location: this.center, radius: '25000', type: ['tourist_attraction'] };
    const nativeCommunityPoi = await this.PlacesUtils.getPlaces(request, 'community');
    nativeCommunityPoi.forEach((location) =>
      poi.community.push(location));

    const stateObj = {};
    stateObj.list = stateInclusion.list;
    stateObj.name = stateInclusion.state;

    return {
      poi,
      state: stateObj,
    };
  }

  /**
   * gets all the lots and creates the lot object, called in initData();
   *
   * returns newLotData{};
   */


  fetchAllCollectionModels = async () => {
    const { salesCenterData } = this.state;
    const newModelData = {};

    const modelPromises = salesCenterData.collections.map((c) => (
      KovaUtils.fetchNewHomeBuilds(c.communityRID)
    ));

    await Promise.all(modelPromises)
      .then((response) => {
        response.forEach((res, i) => {
          newModelData[
            salesCenterData.collections[i].communityRID
          ] = response[i];
        });
      });
    return newModelData;
  }

  handleProductLine = (activeProductLine, productLineText, communityID) => {
    this.setState({
      activeProductLine,
      productLineText,
      selectedCommunityRID: communityID
    });
  }

  initMapMode = () => {
    const { mapMode } = this.props;
    switch (mapMode) {
      case 'surrounding area':
        this.initSurroundingArea();
        // this.setState({ splash: false })
        break;
      case 'points of interest':
        this.initPoi();
        // this.setState({ splash: false })
        break;
      case 'home sites':
        this.initHomeSites();
        break;
      default:
        // TODO: Replace console log with Alert/Message component
        // eslint-disable-next-line no-console
        console.error(`mapMode: ${mapMode} not recognized`);
    }
  }

  initSurroundingArea = () => {
    const {
      markers,
      places,
      salesCenterData,
      lotMapArray,
    } = this.state;
    this.markerUtils.clearMarkers(markers);
    this.map.setTilt(45);
    this.map.setZoom(14);
    this.map.setCenter(this.center);
    if (salesCenterData.altBuilders.length) {
      lotMapArray[0].hide();
      lotMapArray[1].show();
    }
    const placesKeys = Object.keys(places);
    let placesArray = [];
    placesKeys.forEach((key) => {
      placesArray.push(places[key].flat());
    });
    placesArray = placesArray.flat();


    setTimeout(() => {
      const newMarkers = this.markerUtils
        .addMarkersByConfig(this.placesConfig, places, this.setSelectedPlace);
      const checkMarkers = () => {
        const sameLength = newMarkers.length === placesArray.length;
        if (!sameLength) {
          setTimeout(() => {
            checkMarkers();
          }, 1000);
        } else {
          this.setState({ markers: newMarkers, pinsLoaded: true });
        }
      };
      checkMarkers();
    }, 1000);
  }

  initPoi = () => {
    const {
      markers,
      poi,
      salesCenterData,
      lotMapArray,
    } = this.state;
    this.markerUtils.clearMarkers(markers);
    this.map.setTilt(45);
    this.map.setZoom(10);
    this.map.setCenter(this.center);
    if (salesCenterData.altBuilders.length) {
      lotMapArray[0].hide();
      lotMapArray[1].show();
    }

    let placesArray = [];

    const placesKeys = Object.keys(poi);
    placesKeys.forEach((key) => {
      placesArray.push(poi[key].flat());
    });
    placesArray = placesArray.flat();

    setTimeout(() => {
      const newMarkers = this.markerUtils
        .addMarkersByConfig(this.poiConfig, poi, this.setSelectedPlace);
      const checkMarkers = () => {
        const sameLength = newMarkers.length === placesArray.length;
        if (!sameLength) {
          setTimeout(() => {
            checkMarkers();
          }, 1000);
        } else {
          this.setState({ markers: newMarkers, pinsLoaded: true });
        }
      };
      checkMarkers();
    }, 1000);
  }

  setInitialZoom = (salesCenterData, mobileMode) => {
    let zoom = salesCenterData.lotMaps[0].lotMapZoom;
    if (mobileMode && salesCenterData.lotMaps[0].lotMapZoomMobile) {
      zoom = salesCenterData.lotMaps[0].lotMapZoomMobile;
    }
    this.map.setZoom(zoom);
  }

  initHomeSites = () => {
    const {
      markers,
      lotMapArray,
      salesCenterData,
      splash,
    } = this.state;

    let{
      builderSelected,
        mobileMode,
    } = this.state;

    if (this.markerUtils && markers.length > 1) {
      this.markerUtils.clearMarkers(markers);
    }

    this.map.setTilt(0);

    if (salesCenterData.lotMaps.length < 2) {
      this.setInitialZoom(salesCenterData, mobileMode);
    } else {
      this.map.setZoom(15);
    }
    const latLong = getCenterLatLng(salesCenterData);
    this.map.setCenter(new this.google.maps.LatLng(
      latLong[0], latLong[1]
    ));
    lotMapArray.forEach((lotMapOverlay) => {
      lotMapOverlay.show();
    });

    if (!salesCenterData.altBuilders.length) {
      builderSelected = false;
    }

    if (splash && !builderSelected) {
      lotMapArray[1].hide();
      lotMapArray[0].show();

      this.setState({ splash: true, pinsLoaded: true });
    }
    if (splash && builderSelected) {
      lotMapArray[0].hide();
      lotMapArray[1].show();

      this.setState({ splash: true, pinsLoaded: true });
    }
    if (!splash && builderSelected) {
      lotMapArray[0].hide();
      lotMapArray[1].show();
      this.setState({ splash: true, pinsLoaded: true });
    }
    this.setState({ pinsLoaded: true });
  }

  toggleVisibleSplash = () => {
    const { lotMapArray, salesCenterData,mobileMode } = this.state;
    if (salesCenterData.altBuilders.length) {
      lotMapArray[0].hide();
      lotMapArray[1].show();
    }
    this.setInitialZoom(salesCenterData, mobileMode);
    this.setState({ splash: false, builderSelected: true });
  }

  applyLotmapOverlay = () => {
    const { salesCenterData,overrideLotMapRotation,overrideLotMapBounds, editMode } = this.state;
    const lotMapArray = [];

    if (!salesCenterData.altBuilders.length) {
      salesCenterData.lotMaps.forEach((lotMap) => {
        let lotMapOverlay = null;
        let svgEl = document.querySelector(`#${lotMap.lotMapKey}-lotmap`);

        if (salesCenterData.lotMaps.length > 1) {
          svgEl = document.querySelector(`#${lotMap.lotMapKey}-lotmap-${lotMap.mapNumber}`);
        }

        let svgBounds = new this.google.maps.LatLngBounds(
          new this.google.maps.LatLng(
            lotMap.lotMapBounds.bottomLeft[0],
            lotMap.lotMapBounds.bottomLeft[1]
          ),
          new this.google.maps.LatLng(
            lotMap.lotMapBounds.topRight[0],
            lotMap.lotMapBounds.topRight[1]
          )
        );

        if (editMode) {
          if (!overrideLotMapBounds) {
            this.setState(() => ({overrideLotMapBounds: svgBounds}));
          }
          else {
            svgBounds = overrideLotMapBounds;
          }

          var editModeMarkers = updateEditModeMarkers(this.map, this.state.editModeMarkers, svgBounds, () => this.state.overrideLotMapBounds, (svgBounds) => this.setState(() => ({overrideLotMapBounds: svgBounds})));
          this.setState({ editModeMarkers });
        }
        lotMapOverlay = CustomOverlayUtils.addOverlay(
          this.google,
          this.map,
          svgBounds,
          svgEl,
          'overlayMouseTarget',
            overrideLotMapRotation,
            editMode
        );
        lotMapArray.push(lotMapOverlay);
      });
    }
    if (salesCenterData.altBuilders.length) {
      const splashArray = [];
      splashArray.push(document.querySelector(`#${salesCenterData.lotMaps[0].lotMapKey}-lotmap-splash`));
      splashArray.push(document.querySelector(`#${salesCenterData.lotMaps[0].lotMapKey}-lotmap`));

      splashArray.forEach((svgEl) => {
        const svgBounds = new this.google.maps.LatLngBounds(
          new this.google.maps.LatLng(
            salesCenterData.lotMaps[0].lotMapBounds.bottomLeft[0],
            salesCenterData.lotMaps[0].lotMapBounds.bottomLeft[1]
          ),
          new this.google.maps.LatLng(
            salesCenterData.lotMaps[0].lotMapBounds.topRight[0],
            salesCenterData.lotMaps[0].lotMapBounds.topRight[1]
          )
        );
        const lotMapOverlay = CustomOverlayUtils.addOverlay(
          this.google,
          this.map,
          svgBounds,
          svgEl,
          'overlayMouseTarget',
            overrideLotMapRotation
        );
        lotMapArray.push(lotMapOverlay);
      });
    }

    this.setState({ lotMapArray });
  }

  applyCommunityOverlays = () => {
    const { salesCenterData } = this.state;
    const communityOverlays = [];

    if (salesCenterData.allAuxCollections) {
      salesCenterData.allAuxCollections.forEach((auxCollection) => {
        const svgEl = document.querySelector(`#${auxCollection.lotMapKey}`);
        const svgBounds = new this.google.maps.LatLngBounds(
          new this.google.maps.LatLng(
            auxCollection.bounds.bottomLeft[0],
            auxCollection.bounds.bottomLeft[1]
          ),
          new this.google.maps.LatLng(
            auxCollection.bounds.topRight[0],
            auxCollection.bounds.topRight[1]
          )
        );
        const auxOverlay = {
          collectionName: auxCollection.lotMapKey,
          overlay: CustomOverlayUtils.addOverlay(
            this.google,
            this.map,
            svgBounds,
            svgEl,
            'overlayLayer'
          )
        };
        communityOverlays.push(auxOverlay);
      });
    }

    this.setState({ communityOverlays });
  }

  hidePlaces = (category) => {
    const {
      markers,
      places,
      poi
    } = this.state;
    let locations = places;
    if (this.props.mapMode === 'points of interest') {
      locations = poi;
    }
    locations = locations[category]
      .map((place) => place.location.toString());
    const filtered = markers.filter((marker) =>
      locations.includes(marker.position.toString()));
    this.markerUtils.hideMarkers(filtered, category);
  }

  showPlaces = (category) => {
    this.markerUtils.showMarkers(category);
  }

  getSelectedPlace = () => {
    if (this.props.placeId === null) return null;
    const locations = this.getLocations();
    if (locations === null) return null;
    const location = this.PlacesUtils.getPlaceById(locations, this.props.placeId);
    const locationsByType = Object.entries(locations)
        .find((element) => element[0] === location.type)[1];
    const markerIndex = locationsByType.findIndex((element) =>
        element.name === location.name) + 1;
    location.markerIndex = markerIndex;

    if (location !== undefined) {
      const categ = this.placesConfig.find(elem => elem.name === location.type);
      if (categ !== undefined) {
        location.color = categ.color;
      }
    }
    return location;
  }
  getLocations = () => {
    let locations = this.state.places;
    if (locations === null) return null;
    if (this.props.mapMode === 'points of interest') {
      locations = this.state.poi;
    }
    return locations;
  }
  setSelectedPlace = (selection, color) => {
    const {
      places,
      poi,
      salesCenterData,
      mobileMode,
    } = this.state;

    const selectedPlace = this.getSelectedPlace();
    if (!selection) {
      this.props.setPlaceId(null);
      //this.setState({ selectedPlace: "" });
      if (this.props.mapMode === 'surrounding area') {
        this.map.setZoom(14);
        this.map.setCenter(this.center);
      }
      if (this.props.mapMode === 'points of interest') {
        this.map.setZoom(10);
        this.map.setCenter(this.center);
      }
    } else {

      const locations = this.getLocations();
      let newPlace = {};
      // Check if selection is marker or place
      if (!selection.id) {
        newPlace = this.PlacesUtils.findPlaceByMarker(locations, selection);
      } else {
        newPlace = selection;
      }
      if (!selectedPlace || selectedPlace.id !== newPlace.id) {
        newPlace.color = color;

        this.props.setPlaceId(newPlace.id);

        //this.setState({ selectedPlace: newPlace });
      }
      this.map.setCenter(newPlace.location);
      this.setInitialZoom(salesCenterData, mobileMode);
    }
  }

  initalizePlacesData = async () => {
    const { google } = this;

    this.PlacesUtils = new PlacesUtils(this.map);
    this.markerUtils = new MarkerUtils(google, this.map, this.markerClusterer);
    const places = await this.getPlaces();
    const { poi, state } = await this.getPoi();
    this.setState({
      places,
      poi,
      state,
    });
  }

  setMapMode = async (mapMode) => {
    const { places } = this.state;


    if (!places || Object.keys(places).length <= 1) {

      await this.initalizePlacesData();


    }
    this.props.setPlaceId(null);
    this.props.setMapMode(mapMode);

  }

  toggleMobileMenu = () => {
    const {collapseMenu} = this.state;
    if (!collapseMenu) {
      this.clearSelectedLot(false);
    }
    this.setState((prevState) => ({
      collapseMenu: !prevState.collapseMenu,
      collapseMapMode: !prevState.collapseMapMode
    }));
  }

  toggleCollapseMenu = () => {
    this.setState((prevState) => ({
      collapseMenu: !prevState.collapseMenu
    }));
  }

  toggleCollapseMapMode = () => {
    this.setState((prevState) => ({
      collapseMapMode: !prevState.collapseMapMode
    }));
  }


  refreshEditModeInfo = (editModeInfo) => {
    this.setState((prevState) => (
        editModeInfo
    ));
  }

  setClick = () => {
    const {
      mapClicked,
    } = this.state;

    this.setState((prevState) => ({
      streetVIconClicked: !prevState.streetVIconClicked,
    }));
    if (mapClicked) {
      this.setState((prevState) => ({
        mapClicked: !prevState.mapClicked,
      }));
    }
  }

  panoPosition = (google, pos) => {
    const panorama = new google.maps.StreetViewPanorama(
      document.getElementById('pano'), {
        position: pos,
        pov: {
          heading: 80,
          pitch: 10
        }
      }
    );
    this.map.setStreetView(panorama);
  }

  checkMapClick = (google, position) => {
    const {
      streetVIconClicked,
    } = this.state;
    if (streetVIconClicked) {
      this.setState((prevState) => ({ mapClicked: !prevState.mapClicked }));
      this.panoPosition(google, position);
    }
  }

  enablePoiGallery = () => {
    this.setState({ poiGalleryActive: true });
  }

  disablePoiGallery = () => {
    this.setState({ poiGalleryActive: false });
  }

  checkModelParam = () => {
    const { newModelData, salesCenterData } = this.state;
    let selectedModelRID = queryString.parse(window.location.search)
      .selectedModel;
    let selectedCommunityRID;
    let selectedModel;

    if (selectedModelRID) {
      selectedModelRID = Number(selectedModelRID);
      const allModels = Object.entries(newModelData);
      allModels.forEach(([communityRID, models]) => {
        const matchedModel = models.find((model) =>
          model.ModelRID === selectedModelRID);
        if (matchedModel) {
          selectedCommunityRID = communityRID;
          selectedModel = matchedModel;
        }
      });

      const communityName = salesCenterData.collections.find((c) =>
        c.communityRID === Number(selectedCommunityRID)).name;

      const newHome = {};
      newHome.id = selectedModel.ModelRID;
      const formatName = selectedModel.Name.split('(')[0];
      newHome.name = formatName;
      newHome.nhPhoto = selectedModel.PresentationImageUrl;
      newHome.sqf = selectedModel.Sqf;
      newHome.bath = selectedModel.NumBedrooms;
      newHome.bed = selectedModel.NumBaths;
      newHome.price = selectedModel.BasePrice;
      newHome.communityRID = selectedCommunityRID;
      newHome.communityName = communityName;
      newHome.marketingName = selectedModel.MarketingName;
      newHome.status = 'AFP';

      if (selectedModel) {
        this.handleSelectedLotRID(newHome.id, newHome);
      }
    }
  }

  handleSelectedLotRID = (lot, lotObj) => {
    const {mobileMode} = this.state;

    const optSelRID = GlobalConfig.get(GlobalConfig.Key.MARKETHOME_INFO_OPTSELRID);
    const isMarketHomeWithSlsOrd = lotObj && lotObj.status == "QUICKMOVEIN" && lotObj.SlsOrdRID;

    if(isMarketHomeWithSlsOrd && optSelRID > 0){
      lotObj.ExtraMarketHomeData = {JobStage: "Loading...", SelectedOptValue: "Loading...", Handing: "Loading..."};
    }

    this.setState({
      lotType: lotObj.status,
      selectedLotRID: lot,
      lotInfo: lotObj,
      showOptionList: false,
    });
    if (mobileMode) {
      this.setState({
        collapseMapMode: false,
        collapseMenu: false,
      })
    }


    const fetchExtraData = async () => {
        let extraMarketHomeData = await KovaUtils.fetchExtraMarketHomeData(lotObj.SlsOrdRID, optSelRID);
        lotObj.ExtraMarketHomeData = extraMarketHomeData;
        this.setState(lotObj);
    }
    if(isMarketHomeWithSlsOrd && optSelRID > 0){
      fetchExtraData();
    }
  }


  clearSelectedLot = (modelOnly) => {
    if (modelOnly) {
      this.setState({
        showAvailModel: null,
        newHomeSelectedLotRID: null,
      });
    } else {
      this.setState({
        selectedLotRID: null,
        lotType: null,
        showAvailModel: null,
        newHomeSelectedLotRID: null,
      });
    }
  }

  handleOptionList = () => {
    const { mobileMode } = this.state;
    if (mobileMode) {
      this.setState((prevState) => ({
        collapseMenu: !prevState.collapseMenu,
        collapseMapMode: !prevState.collapseMapMode
      }));
    }
    else
    {
      this.setState({ showOptionList: true });
    }
  }

  handleShowAvailModel = (model, lot) => {
    this.setState({
      showAvailModel: model,
      newHomeSelectedLotRID: lot,
    });
  }

  pinsVsLots = () => {
    this.setState((prevState) => ({
      pinsActive: !prevState.pinsActive
    }));
  }


  AmenityCenterSelect = () => {
    const { roomId } = this.state;

    this.socket.emit('navigate amenity center', {
      name: 'navigate amenity center',
      roomId
    });
  }

  turnOffAmenityCenters = () => {
    const { amenityCenters } = this.state;
    this.markerUtils.turnOffAmenityCenter(amenityCenters);
  }

  triggerMatterport = () => {
    this.setState((prevState) => ({
      activeMatterport: !prevState.activeMatterport
    }));
  }

  listenForPrint = () => {
    this.setState((prevState) => ({ triggerPrint: !prevState.triggerPrint }));
  }

  changeSelectedMap = (lotMap) => {
    if (!lotMap) {
      this.initHomeSites();
      this.setState({ selectedMap: null });
      return null;
    }
    if (this.props.mapMode === 'home sites') {
      this.map.setZoom(lotMap.lotMapZoom);
      this.map.setCenter(new this.google.maps.LatLng(
        lotMap.lotMapCenter[0], lotMap.lotMapCenter[1]
      ));
      this.mapFilterLots(lotMap);
      const newAuxLotData = lotMap.auxLotMapCollections;
      this.setState({ selectedMap: lotMap, auxLotData: newAuxLotData });
      return null;
    }
    return null;
  }

  /**
   * filters lots shown on map in the case of multiple lotmaps.
   * a selected map is passed in all lots that do not share
   * the lotmap number will be filtered out.
   *
   * @param {} selectedMap
   *
   * sets SecondaryFilteredLots in state.
   */
  mapFilterLots = (selectedMap) => {
    const { newLotData, salesCenterData } = this.state;

    const selectedMapNumber = Number(selectedMap.mapNumber);

    const newLotConfig = {};

    const lotCommunities = Object.keys(newLotData);
    lotCommunities.forEach((community) => {
      newLotConfig[community] = [];
      newLotData[community].forEach((lot) => {
        if (salesCenterData.lotMaps.length > 1) {
          if (lot.mapNumber === selectedMapNumber) {
            newLotConfig[community].push(lot);
          }
        }
      });
    });
    this.setState({ SecondaryFilteredLots: newLotConfig });
  }

  changeLoadingStatus = (location) => {
    const { svgLoaded, pinsLoaded } = this.state;
    if (location === 'Map') {
      this.setState({ svgLoaded: true });
    }
    if (svgLoaded && pinsLoaded) {
      this.setState({ loaded: true });
    }
  }

  render() {
    const {
      auxLotData,
      collapseMapMode,
      collapseMenu,
      communityDetails,
      mapClicked,
      mapInitialized,
      markers,
      places,
      poi,
      poiGalleryActive,
      roomId,
      scriptLoaded,
      state,
      streetVIconClicked,
      pinsActive,
      selectedLotRID,
      lotInfo,
      lotType,
      showOptionList,
      showAvailModel,
      newHomeSelectedLotRID,
      activeProductLine,
      productLineText,
      triggerPrint,
      activeMatterport,
      newLotData,
      newModelData,
      activeCollections,
      salesCenterId,
      selectedCommunityRID,
      communityTitle,
      lotStatusConfig,
      salesCenterData,
      blacklist,
      matterportConfig,
      splash,
      builderSelected,
      selectedMap,
      SecondaryFilteredLots,
      loaded,
      lotCollections,
      dataInitialized,
      mobileMode,
      editMode,
      overrideLotMapRotation,
      overrideLotMapBounds
    } = this.state;

    const { mapMode } = this.props;
    const selectedPlace = this.getSelectedPlace();
    const configLoaded = blacklist && lotStatusConfig && salesCenterData;
    if (this.map && this.props.mapLocation !== null) {
      const hashJson = JSON.parse(this.props.mapLocation);
      console.log(hashJson);
      this.map.setZoom(hashJson.zoom);
      this.map.panTo(new window.google.maps.LatLng(hashJson.lat, hashJson.lng));
    }
    const isBeingControlled = !!this.props.mapLocation;
    return scriptLoaded && configLoaded ? (
      <div className={styles.App}>
        {!loaded && (
          <div
            styles={styles.loadingScreen}
            style={
              {
                height: '100%',
                width: '100%',
                backgroundColor: 'white',
                zIndex: 10,
                position: 'absolute',
              }
            }
          >
            <LoadingPage message="Loading homesite map" />
          </div>
        )}
        {salesCenterData.lotMaps.length > 1 && selectedMap && (
          <button className={styles.backButton} onClick={() => this.changeSelectedMap(null)} type="button">
            <img src={BackButton} alt="back" />
          </button>
        )}
        {/*
        <div className={styles.mobileMenuButton}>
          <button className={styles.toggleMenu} onClick={() => this.setState({ triggerPrint: !this.state.triggerPrint })} type="button">
            PRINT
          </button>
        </div>
        */}
        { loaded && mobileMode && (
            <div className={styles.mobileMenuButton}>
              <button className={styles.toggleMenu} onClick={() => this.toggleMobileMenu()} type="button">
                {
                  collapseMenu ? 'MENU' : 'MAP'
                }
              </button>
            </div>
        )}
        { loaded && editMode && (
            <EditMode
                google={this.google}
                lotCollections={lotCollections}
                communityTitle={communityTitle}
                overrideLotMapRotation={overrideLotMapRotation}
                overrideLotMapBounds={overrideLotMapBounds}
                refreshEditModeInfo={this.refreshEditModeInfo}/>
        )}
        <div className={styles.container1}>
          <Map
            applyCommunityOverlays={this.applyCommunityOverlays}
            applyLotmapOverlay={this.applyLotmapOverlay}
            auxLotData={auxLotData}
            streetVIconClicked={streetVIconClicked}
            mapClicked={mapClicked}
            mapMode={mapMode}
            pinsActive={pinsActive}
            handleSelectedLotRID={this.handleSelectedLotRID}
            selectedLotRID={selectedLotRID}
            lotInfo={lotInfo}
            lotType={lotType}
            newModelData={newModelData}
            activeProductLine={activeProductLine}
            newHomeSelectedLotRID={newHomeSelectedLotRID}
            activeCollections={activeCollections}
            salesCenterData={salesCenterData}
            lotStatusConfig={lotStatusConfig}
            toggleVisibleSplash={this.toggleVisibleSplash}
            splash={splash}
            socket={this.socket}
            roomId={roomId}
            builderSelected={builderSelected}
            changeSelectedMap={this.changeSelectedMap}
            selectedMap={selectedMap}
            loaded={loaded}
            changeLoadingStatus={this.changeLoadingStatus}
            lotCollections={lotCollections}
          />
        </div>
        <div className={`${styles.container2}`}>
          {mapInitialized && dataInitialized && (
            <>
              <div className={styles.menuWrapper}>
                {poiGalleryActive
                  && <PhotoOverlay selectedPlace={selectedPlace} />}
                <Menu
                    isBeingControlled={isBeingControlled}
                    center={this.center}
                    poiGalleryActive={poiGalleryActive}
                    disablePoiGallery={this.disablePoiGallery}
                    enablePoiGallery={this.enablePoiGallery}
                    collapseMenu={collapseMenu}
                    triggerMatterport={this.triggerMatterport}
                    activeMatterport={activeMatterport}
                    community={salesCenterData}
                    directionsUtils={this.directionsUtils}
                    google={this.google}
                    hidePlaces={this.hidePlaces}
                    map={this.map}
                    mapFilterObject={this.mapFilterObject}
                    mapMode={mapMode}
                    places={places}
                    placesConfig={this.placesConfig}
                    poi={poi}
                    poiConfig={this.poiConfig}
                    roomId={roomId}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={this.setSelectedPlace}
                    showPlaces={this.showPlaces}
                    state={state}
                    socket={this.socket}
                    markers={markers}
                    markerUtils={this.markerUtils}
                    pinsVsLots={this.pinsVsLots}
                    handleSelectedLotRID={this.handleSelectedLotRID}
                    clearSelectedLot={this.clearSelectedLot}
                    lotInfo={lotInfo}
                    lotType={lotType}
                    showOptionList={showOptionList}
                    handleOptionList={this.handleOptionList}
                    handleShowAvailModel={this.handleShowAvailModel}
                    showAvailModel={showAvailModel}
                    handleProductLine={this.handleProductLine}
                    activeProductLine={activeProductLine}
                    newLotData={SecondaryFilteredLots || newLotData}
                    lotCollections={lotCollections}
                    newModelData={newModelData}
                    productLineText={productLineText}
                    activeCollections={activeCollections}
                    salesCenterId={salesCenterId}
                    selectedCommunityRID={selectedCommunityRID}
                    salesCenterData={salesCenterData}
                    lotStatusConfig={lotStatusConfig}
                    matterportConfig={matterportConfig}
                    selectedLotRID={selectedLotRID}
                    splash={splash}
                    builderSelected={builderSelected}
                    selectedMap={selectedMap}
                    changeSelectedMap={this.changeSelectedMap}
                />

              </div>
              {!isBeingControlled && !activeMatterport && loaded && (!mobileMode || collapseMenu) &&(
                <MapModeNav
                  collapseMapMode={collapseMapMode}
                  lotStatusConfig={lotStatusConfig}
                  mapMode={mapMode}
                  placesConfig={this.placesConfig}
                  poiConfig={this.poiConfig}
                  setMapMode={this.setMapMode}
                />
              )}
            </>
          )}
        </div>

        {triggerPrint && (
          <PrintPDF
            auxLotData={auxLotData}
            communityDetails={communityDetails}
            mapMode={mapMode}
            newLotData={newLotData}
            pinsActive={pinsActive}
            handleSelectedLotRID={this.handleSelectedLotRID}
            selectedLotRID={selectedLotRID}
            lotInfo={lotInfo}
            lotType={lotType}
            activeProductLine={activeProductLine}
            newHomeSelectedLotRID={newHomeSelectedLotRID}
            socket={this.socket}
            roomId={roomId}
            listenForPrint={this.listenForPrint}
            activeCollections={activeCollections}
            communityTitle={communityTitle}
            salesCenterData={salesCenterData}
            lotStatusConfig={lotStatusConfig}
            selectedMap={selectedMap}
            lotCollections={lotCollections}
          />
        )}
      </div>
    ) : (
      null
    );
  }
}

export default App;
