import axios from 'axios';
import GlobalConfig from "./utils/GlobalConfig";

export default class KovaUtils {
  static fetchCommunityLots = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetLots/${commRID}?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }
  static fetchExtraLotData = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}GetExtraLotData?CommunityRID=${commRID}&kovaEnv=prod`);
    return response.data;
  }
  static fetchExtraMarketHomeData = async (slsOrdRID, optSelRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}GetExtraMarketHomeData?SlsOrdRID=${slsOrdRID}&OptSelRID=${optSelRID}&kovaEnv=prod`);
    return response.data;
  }

  static fetchCommunityMarketHomes = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetMarketHomes/${commRID}?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }

  static fetchNewHomeBuilds = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetModels/${commRID}?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }

  static fetchCustomInfo = async (SlsOrdRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}CustomFields?refObjType=SlsOrd&refObjRID=${SlsOrdRID}&kovaEnv=prod`);
    return response.data;
  }

  static fetchCommunities = async () => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetList?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }
}
