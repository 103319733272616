import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = ({
  clickHandler,
  color,
  icon,
  text
}) => {
  const backButtonClass = text === 'back' ? styles.backButton : '';
  return (
    <button
      className={`${styles.Button} ${backButtonClass}`}
      onClick={clickHandler}
      style={{ background: color }}
      type="button"
    >
      <div>
        <img src={icon} alt={text} />
      </div>
      <div>{text}</div>
    </button>
  );
};

Button.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Button;
