// eslint-disable-next-line max-classes-per-file
class CustomOverlayUtils {
  static addOverlay = (google, map, svgBounds, svgEl, paneLayer, rotation, editMode) => {
    class SvgOverlay extends google.maps.OverlayView {
      constructor(bounds, svg) {
        super();
        this.bounds = bounds;
        this.svg = svg;
        this.map = map;
        this.rotation = rotation;
        this.editMode = editMode;

        super.setMap(map);
      }

      /**
        Will be called when the map is ready for the overlay to be attached.
      */
      onAdd = () => {
        const { svg } = this;
        svg.style.position = 'absolute';

        // Add the SVG element to a map pane/layer that is able to receive mouse
        // events.
        const panes = super.getPanes();
        panes[paneLayer].appendChild(svg);
      }

      /**
        Whenever we need to (re)draw the overlay on the map, including when
        first added.
      */
      draw = () => {
        // Calculate viewport pixels from the image's lat/lng bounds.
        const projection = super.getProjection();
        const { bounds } = this;
        const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
        const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

        // Place/resize the SVG element.
        const s = this.svg.style;
        s.left = `${sw.x}px`;
        s.top = `${ne.y}px`;
        s.width = `${(ne.x - sw.x)}px`;
        s.height = `${(sw.y - ne.y)}px`;
        if (this.rotation) {
          s.transform = 'rotate(' + this.rotation + 'deg)';
        }
        if (this.editMode) {
          s.backgroundColor = 'rgba(255,0,0,0.1)';
        }
      }

      hide = () => {
        const { svg } = this;
        if (svg) {
          svg.style.visibility = 'hidden';
        }
      }

      show = () => {
        const { svg } = this;
        if (svg) {
          svg.style.visibility = 'visible';
        }
      }

      toggle = () => {
        const { svg } = this;
        if (svg) {
          if (svg.style.visibility === 'hidden') {
            this.show();
          } else {
            this.hide();
          }
        }
      }
    }

    return new SvgOverlay(svgBounds, svgEl);
  }
}

export default CustomOverlayUtils;
