class lotMapUtils {
  static getLotStatus = (lot, lotStatusConfig) => {
    const lotStatusArr = Object.entries(lotStatusConfig);
    const lotStatus = lotStatusArr.find((entry) => {
      const { statusTypes } = entry[1];
      let isMatch;
      statusTypes.forEach((statusType) => {
        if (statusType.type === lot.Type && statusType.status === lot.Status) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    if (lotStatus) {
      return lotStatus;
    }

    // eslint-disable-next-line no-console
    console.error('lot statusType not found in lotStatusConfig', lot);
    const unknownStatus = lotStatusArr.find((entry) => entry[0] === 'UNKNOWN');
    return unknownStatus;
  }
}

export default lotMapUtils;
