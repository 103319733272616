class DirectionsUtils {
  constructor(google, map) {
    this.google = google;
    this.map = map;
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setMap(map);
  }

  calcRoute = (start, end, travelMode) => {

    const request = {
      origin: start,
      destination: end,
      travelMode
    };

    this.directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        this.directionsRenderer.setDirections(result);
        this.directionsRenderer.setPanel(document.getElementById('directions-panel'));
      }
    });
  }

  resetDirections = () => {
    this.directionsRenderer.set('directions', null);
  }
}

export default DirectionsUtils;
