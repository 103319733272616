// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import GlobalConfig from "./utils/GlobalConfig";
import AppContainer from "./components/AppContainer";


fetch(`${process.env.PUBLIC_URL}/envconfig.json`)
    .then(response => response.json())
    .then((data) => {
        GlobalConfig.setConfig(data);

        const sp = new URLSearchParams(window.location.search);
        const editMode = sp.has("editmode") && sp.get("editmode") === "true";

        ReactDOM.render(<AppContainer editMode={editMode}/>, document.getElementById('root'));



        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
