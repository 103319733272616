import React from 'react';
import propTypes from 'prop-types';
import styles from './Rating.module.css';

interface IRatingProps {
  rating : number | (() => void),
  card: boolean
}
const Rating = ({ rating, card = false } : IRatingProps) => {
  const isCard = () => {
    if (card) {
      return true;
    }
    return false;
  };

  const listStars = () => {
    const scale = [1, 2, 3, 4, 5];
    let correctedRating = typeof rating !== 'number' ? 0 : rating.valueOf();
    return scale.map((num) => {
      let fillClass;
      if (correctedRating === 0) {
        fillClass = styles.fillNone;
      }
      if (num === correctedRating) {
        fillClass = styles.fillFull;
      }
      if (num > correctedRating) {
        if (num - correctedRating < 1) {
          fillClass = styles.fillHalf;
        } else {
          fillClass = styles.fillNone;
        }
      } else {
        fillClass = styles.fillFull;
      }
      return <span key={num} className={isCard() ? `${styles.starCard} ${fillClass}` : `${styles.star} ${fillClass}`} />;
    });
  };

  const printRating = () => {
    if (typeof rating !== 'number') {
      return 'no reviews:';
    }
    return `${rating}`;
  };

  return (
    <div className={isCard() ? styles.RatingCard : styles.Rating}>
      {printRating()}
      {listStars()}
    </div>
  );
};

export default Rating;
