import KovaUtils from '../../../KovaUtils';
import GlobalConfig from "../../../utils/GlobalConfig";

export async function getCommunityDetails(salesCenterData) {
  const communityDetails = {};
  const communities = await KovaUtils.fetchCommunities();
  salesCenterData.collections.forEach((collection) => {
    communityDetails[collection.communityRID] = communities.find(
      (community) => community.CommunityRID === collection.communityRID
    );
  });

  return communityDetails;
}

function setConfigEnv(hostname) {
  const hostEnvs = {
    dev: [],
    test: [],
    prod: ['bimaire.app', 'dev.php.com', 'bimairedev.app', 'localhost', 'bimairetest.app', '192.168.50.38']
  };
  let configEnv;

  Object.entries(hostEnvs).forEach(([key, value]) => {
    if (value.includes(hostname)) {
      configEnv = key;
    }
  });

  if (!configEnv) {
    //For the new deployment model, always use prod
    if (hostname.includes(".bimairedev.app") || hostname.includes(".bimaire.app")) {
      configEnv = 'prod';
    }
    else {
      // eslint-disable-next-line no-console
      console.error(`host not recognized: ${hostname}`);
      configEnv = 'dev';
    }
  }

  return configEnv;
}

export async function fetchJSONFile(key) {
  const { hostname } = window.location;
  const configEnv = setConfigEnv(hostname);
  const overrides = GlobalConfig.get(GlobalConfig.Key.OVERRIDES);
  const overrideUrl = overrides && overrides[key];
  const urlFromVsc = `${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}AppFiles/config/${configEnv}/${key}`;
  const url = overrideUrl || urlFromVsc;
  const response = await fetch(url);
  const json = await response.json();

  return json;
}

export async function fetchLots(communityRIDs) {
  const lots = {};
  const promises = communityRIDs.map((communityRID) =>
    KovaUtils.fetchCommunityLots(communityRID));

  const result = await Promise.all(promises);
  result.forEach((lotData, i) => {
    lots[communityRIDs[i]] = lotData;
  });

  return lots;
}
