class MatterPort {
  static getMatterportLink = (communityRID, modelRID, config) => {
    if (config[communityRID]) {
      const model = config[communityRID].find((m) => m.modelRID === modelRID);
      if (model) {
        return model.link;
      }
      return null;
    }
    return null;
  }
}

export default MatterPort;
