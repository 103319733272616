/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch';
import CheckBox from '../../../shared/CheckBox/CheckBox';
import Card from './Card/Card';
import ListItem from './ListItem/ListItem';
import styles from './Dropdown.module.css';

class Dropdown extends Component {
  state = {
    active: false,
    hide: false
  };

  componentDidMount() {
    this.checkIndex();
  }

  checkIndex = () => {
    if (this.props.index === 0) {
      this.setState({ active: true });
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
  }

  handleCheckboxClick = (category) => {
    const { hide } = this.state;
    const { hidePlaces, showPlaces } = this.props;
    if (hide) {
      showPlaces(category);
    } else {
      hidePlaces(category);
    }
    this.setState((prevState) => ({
      hide: !prevState.hide
    }));
  }

  listCards = () => {
    const {
      itemClickHandler,
      items,
      color,
      mapMode
    } = this.props;
    return items.map((item, index) => (
      <Card
        key={(item.communityRID || '') + item.id}
        clickHandler={itemClickHandler}
        color={color}
        item={item}
        index={index}
        mapMode={mapMode}
      />
    ));
  }

  listItems = () => {
    const {
      itemClickHandler,
      items,
      color,
      mapMode,
    } = this.props;
    return items.map((item, index) => (
      <ListItem
        key={(item.communityRID || '') + item.id}
        clickHandler={itemClickHandler}
        color={color}
        item={item}
        index={index}
        mapMode={mapMode}
      />
    ));
  }

  render() {
    const {
      categoryKey,
      checkbox,
      heading,
      listType
    } = this.props;
    const { active, hide } = this.state;

    return (
      <div
        className={`${styles.Dropdown} ${active ? styles.active : ''}`}
      >
        <header>
          <div className={styles.headingWrapper}>
            {checkbox && (
              <CheckBox
                active={!hide}
                clickHandler={() => this.handleCheckboxClick(categoryKey)}
              />
            )}
            <h4>{heading}</h4>
          </div>
          <div className={styles.headingWrapperToggle}>
            <ToggleSwitch
              active={active}
              clickHandler={this.handleClick}
            />
          </div>
        </header>
        <ol>
          {listType === 'list' ? this.listItems() : this.listCards()}
        </ol>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  checkbox: true
};

Dropdown.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  checkbox: PropTypes.bool,
  listType: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  hidePlaces: PropTypes.func.isRequired,
  itemClickHandler: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapMode: PropTypes.string.isRequired,
  showPlaces: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired
};

export default Dropdown;
