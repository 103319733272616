import React from 'react';
import PropTypes from 'prop-types';
import styles from './ListItem.module.css';
import {parsePriceInThousands, GetPriceForItem} from "../../LotMenu/LotMenuUtils";

const ListItem = ({
  clickHandler,
  color,
  index,
  item,
  mapMode,
}) => {

    let price = GetPriceForItem(item);
    return (
        <li key={item.id} className={styles.cardBox}>
            <div className={styles.cardIndex}>{`${index + 1}.`}</div>
            <div
                className={styles.itemInfo}
                role="button"
                tabIndex={0}
                onClick={() => {
                    clickHandler(item, color);
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        clickHandler(item, color);
                    }
                }}
            >
                {item.name}
                {mapMode === 'home sites' && (price) && (
                    <span className={styles.price}>
          				$
                        {`${price}`}
                        K
        </span>
                )}
            </div>
        </li>
    );
}

ListItem.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  mapMode: PropTypes.string.isRequired
};

export default ListItem;
