import {match} from "assert";

export const parsePriceInThousands = (priceString:string) => {
    if(priceString){
        let priceInThousands = parseInt(priceString.replace('$','').replace(',','').trim()) / 1000;
        //round to hundreds
        return Math.round(priceInThousands * 10) / 10;
    }
    return 0;
}

export const  GetPriceForItem = (item: any) => {
    let price;
    if (item.price) {
        price = parsePriceInThousands(item.price);
    } else if (item.status === "AVAILABLE" && item.lotPremium) {
        price = parsePriceInThousands(item.lotPremium);
    } else if (item.status === "QUICKMOVEIN" && item.QuickPrice) {
        if(!isNaN(parseFloat(item.QuickPrice)) && isFinite(item.QuickPrice)){ //check if QuickPrice is number
            price = Math.round(item.QuickPrice / 1000  * 10) / 10; //price in thousands rounded to hundreds
        }
    }
    return price;
}

interface INewObj {
    Name: string;
    price: number;
    sqf: string;
    bed: string;
    bath: string;
    photo: string;
    marketingName:string;
    keyIndex:number;
    communityRID:number;
}

export const getModelDetails = (lotInfo:any, activeModelData:any) => {
    const modelData: INewObj[] = [];
    activeModelData.forEach((obj:any) => {
        const newObj: Partial<INewObj> = {};
        newObj.Name = obj.Name.split('(')[0].trim();
        let numericalPrice = parsePriceInThousands(obj.BasePrice);
        if(lotInfo && lotInfo.lotPremium){
            numericalPrice += parsePriceInThousands(lotInfo.lotPremium);
        }
        newObj.price = numericalPrice;

        newObj.sqf = obj.DescrSqf;
        newObj.bed = obj.DescrBedrooms;
        newObj.bath = obj.DescrBaths;
        newObj.photo = obj.PresentationImageUrl;
        newObj.marketingName = obj.MarketingName;
        newObj.keyIndex = obj.ModelRID;
        newObj.communityRID = obj.communityRID;

        modelData.push(newObj as INewObj);
    });

    return modelData;
};